'use strict';
/**
 * Handles everything that has to do with tokens.
 *
 * A token (more specific, a token object) consists of the following attributes:
 *      - token:                        this is the token itself, a random sequence that the Miniserver knows
 *      - tokenRights/msPermission:     a bitmap specifying what permissions a token has, tokenRights is used by the
 *                                      Miniserver, inside the app we call it msPermission.
 *      - validUntil                    seconds since 1.1.2009 - after that time, the token will expire
 *      - username                      Stored only inside the app to make it easier to handle the tokens.
 */

CommunicationComp.factory('TokenHandlerExt', function () {//fast-class-es6-converter: These statements were moved from the previous inheritWith function Content

    var TIMESPAN_HOUR = 1000 * 60 * 60,
        // = 1 Hour
        TIMESPAN_DAY = TIMESPAN_HOUR * 24,
        // = 1 Day
        TIMESPAN_WEEK = TIMESPAN_DAY * 7,
        // = 1 Week
        MIN_DELAY = 2000,
        // = 0,5 seconds
        APP_LEGACY_LIFESPAN = 4 * TIMESPAN_WEEK,
        // = 4 Weeks
        APP_EXTENDED_LIFESPAN = 8 * TIMESPAN_WEEK,
        // = 8 Weeks
        // WI_LEGACY_LIFESPAN = 2 * TIMESPAN_HOUR,      // = 2 Hours
        // WI_EXTENDED_LIFESPAN = 12 * TIMESPAN_HOUR,   // = 12 Hours
        OTHER_LEGACY_LIFESPAN = 30 * 1000,
        // = 30 Secs
        OTHER_EXTENDED_LIFESPAN = TIMESPAN_HOUR,
        // = 1 Hour
        MIN_LIFESPAN_RATIO = 0.5; // after half of the tokens lifespan has passed, it needs to be refreshed.

    return class TokenHandler extends Components.Extension {
        constructor(component, extensionChannel) {
            super(...arguments);
            this.tokenMap = {};
            this._permissionRequestPrms = null; // ensure old tokens are removed

            component.on(CommunicationComp.ECEvent.Pause, this._stopAllKeepAlives.bind(this));
            component.on(CommunicationComp.ECEvent.StopMSSession, this.reset.bind(this)); // the communication has been closed. Reset to prevent refreshing tokens on a closed connection.

            component.on(CommunicationComp.ECEvent.ConnClosed, this._stopAllKeepAlives.bind(this));
        }

        /**
         * Will try to acquire a token for this user with this password. When the returned promise resolves, it will
         * provide an object containing both the acquired token and a oneTimeSalt.
         * @param user
         * @param password|tokenObj
         * @param msPermission     the permission that token needs to grant.
         * @returns {*} a promise that resolves with an object containing the token and a oneTimeSalt
         */
        requestToken({
                         user,
                         password,
                         tokenObj,
                         msPermission
                     }) {
            Debug.Tokens && console.log(this.name, "requestToken: " + user + " - Perm: " + this._translPerm(msPermission) + ", Stack: " + JSON.stringify(getStackObj()));
            let tokenDef = Q.defer();

            if (tokenObj) {
                tokenDef.resolve(this._getTokenWithPermissionUsingToken({
                    requestToken: tokenObj,
                    newTokenPermission: msPermission
                }));
            } else if (password) {
                let cmd,
                    pwHash,
                    hash,
                    devInfo = getDeviceInfo(),
                    devUuid = this._getDeviceUuid();

                tokenDef.resolve(this._requestUserSalt(user).then(saltObj => {
                    // create a hash of the (salted) password
                    pwHash = VendorHub.Crypto[saltObj.hashAlg](password + ":" + saltObj.salt);
                    pwHash = pwHash.toUpperCase(); // hash with user and otSalt

                    hash = this._otHash(user + ":" + pwHash, saltObj.oneTimeSalt, saltObj.hashAlg); // create the getToken cmd

                    cmd = Commands.format(this._getGetTokenCommand(), hash, encodeURIComponent(user), msPermission, devUuid, devInfo);
                    Debug.Tokens && console.log("   request a token: " + cmd);
                    return this.component.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(tResult => getLxResponseValue(tResult));
                }));
            } else {
                tokenDef.reject(new Error("Neither 'password' nor 'tokenObj' have been supplied!"));
            }

            return tokenDef.promise.then(newTokenObj => {
                let tkObj;
                Debug.Tokens && console.log("    token received! perm: " + this._translPerm(newTokenObj.tokenRights));
                Debug.Tokens && console.log("    token received! token: " + newTokenObj.token);

                this._setOriginalUsername(user);

                tkObj = this._prepareTokenObj(newTokenObj, user); // store in this extension. so it can be kept alive & so on.

                this._storeTokenObj(tkObj); // put key (otSalt) onto the result object.


                tkObj.key = newTokenObj.key;
                return tkObj;
            }, err => {
                let errObj = {
                    user: user
                };

                try {
                    errObj.code = getLxResponseCode(err);
                    errObj.value = getLxResponseValue(err);
                } catch (ex) {
                    errObj.value = err;
                    errObj.code = 0;
                }

                console.error(this.name, "requestToken: Could not acquire a token! " + JSON.stringify(errObj));
                throw errObj; // forward so the error can be handled on the outside.
            });
        }

        /**
         * This method is called before the communication to a new Miniserver is established.
         */
        reset() {
            Debug.Tokens && console.log(this.name, "reset");

            this._stopAllKeepAlives();
            this.tokenMap = {};
        }

        /**
         * Will look a token with a specific permission from the handled tokens.
         * @param msPermission     what permission the token needs to grant
         * @param [user]        optional. can be specified that the token needs to be from a specific user.
         * @param [ignoreExpiration] optional. can be used to avoid checking for the expiration date.
         * @returns {*}         an object containing the token, the user, the rights and so on.
         */
        getToken(msPermission, user, ignoreExpiration = false) {
            if (msPermission !== MsPermission.NONE) {
                Debug.Tokens && console.log(this.name, "getToken: permission " + this._translPerm(msPermission) + " for " + user);
            }

            let validateUserFn = tokenObj => !user || user === tokenObj.username,
                token;

            // 1. Check if tokens exist with the exact permission
            token = Object.values(this.tokenMap).find(tokenObj => {
                    return validateUserFn(tokenObj) && tokenObj.msPermission === msPermission;
                });

            // 2. Check if there is a token that includes the permission
            if (!token) {
                token = Object.values(this.tokenMap).find(function (tokenObj) {
                    return validateUserFn(tokenObj) && hasBit(tokenObj.msPermission, msPermission);
                });
            }

            if (token && Feature.JWT_SUPPORT && !ignoreExpiration && token.jwt && token.jwt.payload) {
                let compareTS = token.jwt.payload.exp;
                // comparing against UTC taken from the device is wrong and will break if the device time is wrong, hence we use the Miniserver time for comparison
                const currentMiniserverTs = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME).unix();
                if (compareTS < currentMiniserverTs) {
                    Debug.Tokens && console.log(this.name, "   JWT token will be deleted due to expire date (" + moment.unix(compareTS).format(DateType.DateAndTimeShortWithSeconds) + ")");
                    this._deleteToken(token.token); // it's a token object not the string!
                    token = null;
                }
            }

            if (msPermission !== MsPermission.NONE) {
                Debug.Tokens && console.log("       token found: " + (!!token));
            }
            return token;
        }

        /**
         /**
         * Will resolve if a valid token exists & reject if no token exists or the token isn't valid.
         * @param msPermission  the permission we want
         * @param username      the user for which we need to have the token.
         */
        getVerifiedToken(msPermission, username) {
            Debug.Tokens && console.log(this.name, "getVerifiedToken: permission " + this._translPerm(msPermission) + " for " + username);
            var tokenObj = this.getToken(msPermission, username),
                promise;

            if (tokenObj && this._isConnectionToken(tokenObj) && !this._hasConnectionPermission(msPermission)) {
                Debug.Tokens && console.log(this.name, "    permission (" + this._translPerm(msPermission) + ") is granted on the connection token! (" + this._translPerm(tokenObj.msPermission) + ")");
                return Q.resolve(tokenObj.token);

            } else if (this._getCachedVerifyPromise(tokenObj)) {
                // by intervening in this location instead of checkOrRefreshToken (or later) --> it is ensured that if
                // the token is rejected, also only one killToken request would be attempted. This rids the app of
                // various calls to getkey + checkToken, reducing effort on the Miniserver and hence reducing the risk
                // of a 401 due to too many requests.
                Debug.Tokens && console.log(this.name, "   JWT ("+ this._translPerm(msPermission) + ") is currently being verified, return with previous promise!");
                promise = this._getCachedVerifyPromise(tokenObj);

            } else if (tokenObj) {
                Debug.Tokens && console.log(this.name, "   verifying JWT-infos: perm=" + tokenObj.msPermission + ", exp=" + moment.unix(tokenObj.jwt.payload.exp).format(DateType.DateAndTimeShortWithSeconds));
                promise = this._checkOrRefreshToken(tokenObj).then(function (res) {
                    Debug.Tokens && console.log(this.name, "   token is verified: " + this._translPerm(msPermission) + " for " + username);
                    return tokenObj.token;
                }.bind(this), function (err) {
                    this._killToken(tokenObj);

                    throw new Error(err);
                }.bind(this));

                // ensure that subsequent requests for the same token will not fire a new one, while this is still ongoing.
                this._cacheVerifyPromise(tokenObj, promise);

            } else {
                promise = prmsfy(false, null, new Error("No token!"));
            }

            return promise;
        }

        _cacheVerifyPromise(tokenObj, promise) {
            const ogToken = tokenObj.token; // check to avoid it maybe being extended, hence modifying it.
            this._verifyTokenRqMap = this._verifyTokenRqMap || {};
            this._verifyTokenRqMap[ogToken] = promise;
            promise.finally(() => {
                delete this._verifyTokenRqMap[ogToken];
            });
        }

        _getCachedVerifyPromise(tokenObj) {
            this._verifyTokenRqMap = this._verifyTokenRqMap || {};
            if (tokenObj && this._verifyTokenRqMap.hasOwnProperty(tokenObj.token)) {
                return this._verifyTokenRqMap[tokenObj.token];
            } else {
                return false;
            }
        }

        /**
         * Will lookup the token granting the permission provided and then ensures that this token will be kept alive
         * until killToken is called on the msPermission or the keepalive has been stopped.
         * @param msPermission         the permission of the token that is to be kept alive
         */
        keepPermissionTokenAlive(msPermission) {
            Debug.Tokens && console.log(this.name, "keepPermissionTokenAlive: " + this._translPerm(msPermission), getStackObj());
            var storedObj = this.getToken(msPermission);

            if (storedObj && this._isConnectionToken(storedObj) && !this._hasConnectionPermission(msPermission)) {
                Debug.Tokens && console.log(this.name, "   permission (" + this._translPerm(msPermission) + ") is part of the connection token! (" + this._translPerm(storedObj.msPermission) + ")");
            } else if (storedObj) {
                this._stopKeepAlive(storedObj, msPermission);

                this._startKeepAlive(storedObj, msPermission);
            } else {
                console.error("Cannot keep a a token alive who's data is not known.");
            }
        }

        /**
         * Will stop the keepalive for the token with this permission. It will not stop all keepalives for this token,
         * only the one that has beens tarted for this very permission (-combination)
         * @param msPermission
         */
        stopMsPermissionKeepAlive(msPermission) {
            Debug.Tokens && console.log(this.name, "stopMsPermissionKeepAlive: " + this._translPerm(msPermission));
            // when stopping the keepalive, ignore the expiration -> safety (should not occur)
            var storedObj = this.getToken(msPermission, null, true);

            if (storedObj) {
                this._stopKeepAlive(storedObj, msPermission);
            } else {
                console.error("Cannot stop a tokens keepalive who's data is not known.");
            }
        }

        /**
         * ensure the token is no longer kept alive by this extension
         * @param token
         */
        removeFromKeepAlive(token) {
            if (this._tokenIsKeptAlive(token)) {
                this._stopKeepAlive(this._getTokenObj(token));
            }
        }

        /**
         * Will iterate and kill all tokens that are being handled in here.
         */
        killAllTokens() {
            Debug.Tokens && console.log(this.name, "killAllTokens");
            var prmses = [],
                connToken; // important - clone, otherwise the map would be mutated while being iterated

            cloneObject(Object.values(this.tokenMap)).forEach(function (tkObj) {
                // kill the connection token last, otherwise the other tokens cannot be killed (socket closes)
                if (this._isConnectionToken(tkObj)) {
                    connToken = tkObj;
                } else {
                    prmses.push(this._killToken(tkObj));
                }
            }.bind(this)); // the other tokens have already been killed. kill this one too.

            connToken && prmses.push(this._killToken(connToken));
            return Q.all(prmses);
        }

        /**
         * Wills a token with an optional username passed in, but with a known token permission. If no token exists,
         * it won't do anything.
         * @param msPermission
         * @param [username]    if not provided, the extension will look up the user stored for this token
         */
        killTokenWithMsPermission(msPermission, username) {
            Debug.Tokens && console.log(this.name, "killTokenWithMsPermission: " + this._translPerm(msPermission));
            var tokenObj = this.getToken(msPermission, username);

            // check if the token to kill is the connection token, only kill it if the permission specifically requests it.
            if (tokenObj && (!this._isConnectionToken(tokenObj) || this._hasConnectionPermission(msPermission))) {
                this._killToken(tokenObj);
            } else if (tokenObj) {
                Debug.Tokens && console.info("Won't kill the token, perms : " + this._translPerm(tokenObj.msPermission));
            } else {
                Debug.Tokens && console.info("No token to kill");
            }
        }

        /**
         * Sends the command via the websocket, but adds the token provided as additional authentication to the
         * command. E.g. used to edit users or to use the expert mode.
         * @param cmd
         * @param token
         * @param user
         * @param [oneTimeSalt] optional oneTimeSalt, requested if not already provided.
         * @param [rqFlags] optional, provided to pass on infos on the request such as noLLResponse (= not wrapped in {LL:{value, code}} format)
         * @result {Promise}
         */
        sendWithToken(cmd, token, user, oneTimeSalt, rqFlags= {}) {
            var saltPrms,
                authCmd = cmd;

            if (oneTimeSalt) {
                saltPrms = Q.fcall(function () {
                    return oneTimeSalt;
                });
            } else {
                saltPrms = this._getOneTimeSalt();
            }

            return saltPrms.then(function (otSalt) {
                authCmd += "?" + Commands.format(Commands.TOKEN.AUTH_ARG, this._otHash(token, otSalt), encodeURIComponent(user));
                return this.component.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL, null, rqFlags);
            }.bind(this), function (err) {
                console.error("Could not launch the cmd " + cmd + " for user " + user + "! " + JSON.stringify(err));
                throw new Error(err);
            });
        }

        /**
         * This method is called after successfully logging in with an existing token. The token will then be auto-
         * matically kept alive for the time being.
         * @param tokenObj      the token object that has been acquired (including user, permission/rights)
         * @param user          what user was this token for?
         * @returns {{token: *, msPermission: *, username: *, validUntil: *}}
         */
        addToHandledTokens(tokenObj, user) {
            Debug.Tokens && console.log(this.name, "addToHandledTokens", getStackObj());

            var obj = this._prepareTokenObj(tokenObj, user);

            this._storeTokenObj(obj);

            this.keepPermissionTokenAlive(obj.msPermission);
            return obj;
        }

        /**
         * Will check if the token provided has got the "insecure password" flag set. As now we no longer know if
         * a password is insecure or not as we don't store the password anymore - the Miniserver knows if it's insecure
         * and will publish this info along with the token.
         * @param token
         */
        hasInsecurePassword(token) {
            var tokenObj = this._getTokenObj(token);

            return tokenObj && !!tokenObj.unsecurePass;
        }

        /**
         * When the password of the active user was changed, all tokens except for the active connection token will
         * be invalidated and therefore can be removed. The active communication token (App or Web) will be kept alive
         * and have to be refreshed as the unsafePassword flag needs to be updated.
         * @param newPass   --> new password of the user
         * @param username  --> username of the current logged in user
         */
        respondToPasswordChange(newPass, username) {
            Debug.Tokens && console.log(this.name, "respondToPasswordChange"); // store a reference to the connection token. it will be reused later

            var connToken = this.getToken(MsPermission.APP) || this.getToken(MsPermission.WEB),
                restorePrms,
                userTokensPermissions = []; // safe permissions to request them later after we got the new connection token

            Object.values(this.tokenMap).forEach(function (tokenObj) {
                if (!this._isConnectionToken(tokenObj) && tokenObj.username === username) {
                    userTokensPermissions.push(tokenObj.msPermission);
                }
            }.bind(this)); // stop keepalives & reset the token map (= removes all other tokens)

            this.reset(); // delete all attributes that will be reassigned when the token is refreshed

            delete connToken.seconds;
            delete connToken.validUntil;
            delete connToken.unsecurePass; // launch refresh right away to ensure the attributes (especially unsafePassword) are updated.
            // IMPORTANT!
            // Don't user _safeRefreshToken. It will kill the old token after getting the new one.
            // The old Token is the connection if we kill it, the miniserver closes the connection.

            return this._refreshToken(connToken).then(function (res) {
                Debug.Tokens && console.log(this.name, "successfully refreshed after password change.");

                if (Feature.TOKEN_REFRESH_AND_CHECK) {
                    this._storeNewConnectionToken(connToken, res);

                    restorePrms = this._restoreOldPermissionTokens(res.username, newPass, userTokensPermissions);
                } else {
                    updateObject(connToken, res); // store the new attributes (unsecurePass, validUntil & seconds)

                    this.addToHandledTokens(connToken, connToken.username);
                    restorePrms = Q.resolve();
                }

                return restorePrms;
            }.bind(this));
        }

        /**
         * This function requests new tokens with the needed permissions
         * @param username  current username
         * @param newPass   new password
         * @param neededPermissions array of permissions
         * @returns {Q.Promise<[unknown, unknown, unknown, unknown, unknown, unknown]>}
         * @private
         */
        _restoreOldPermissionTokens(username, newPass, neededPermissions) {
            this._restorePermissionsDef = Q.defer();

            this._restorePermission(username, newPass, neededPermissions);

            return this._restorePermissionsDef.promise;
        }

        _restorePermission(username, newPass, neededPermissions) {
            if (neededPermissions.length) {
                if (!this._permissionRequestPrms) {
                    this._permissionRequestPrms = this.requestToken({
                        user: username,
                        password: newPass,
                        msPermission: neededPermissions[0]
                    }).then(function () {
                        this._permissionRequestPrms = null;
                        neededPermissions = neededPermissions.slice(1);
                        return this._restorePermission(username, newPass, neededPermissions);
                    }.bind(this));
                }
            } else {
                this._restorePermissionsDef.resolve();
            }
        }

        /**
         * After calling this method, the token provided will no longer be valid. All clients using it loose their
         * access rights.
         * @param tokenObj     the tokenObj containing the token that is to be killed
         * @returns {*}
         */
        _killToken(tokenObj) {
            console.warn(this.name, "_killToken: " + this._translPerm(tokenObj.msPermission));
            var username = tokenObj.username;
            var token = tokenObj.token;
            this.removeFromKeepAlive(token);

            this._deleteToken(token);

            return this._sendTokenCommand(Commands.TOKEN.KILL, token, username).fail(function (err) {
                if (err === SupportCode.WEBSOCKET_CLOSE) {
                    console.error(this.name, "   Connection Token killed, socket closed!");
                } else if (err && err.LL) {
                    console.error("Kill Token command failed. It might already be dead by now! " + JSON.stringify(err));
                } else {
                    console.error("Connection issue while killing the token. " + JSON.stringify(err));
                }
            }.bind(this));
        }

        /**
         * Will ask the Miniserver whether or not the token is still valid. Resolves if it is, rejects if it's not.
         * @param tokenObj
         * @return {*}
         * @private
         */
        _checkToken(tokenObj) {
            const randomId = getRandomIntInclusive(10000, 99999);
            (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "_checkToken: " + tokenObj.username + " - Perm: " + this._translPerm(tokenObj.msPermission) + " #rndId=" + randomId + ", token=" + tokenObj.token);

            return this._sendTokenCommand(Commands.TOKEN.CHECK, tokenObj.token, tokenObj.username).then(function (result) {
                Debug.Tokens && console.log(this.name, "    check for token succeeded! #rndId=" + randomId);
                return getLxResponseValue(result);
            }.bind(this), function (err) {
                console.error(this.name, "    check for token " + tokenObj.username + " with permission " + this._translPerm(tokenObj.msPermission) + " failed! #rndId=" + randomId);
                console.error(JSON.stringify(err));
                throw err;
            }.bind(this));
        }

        /**
         * Will expand the tokens lifespan. When the promise resolves, it returns an object that contains the infor
         * until when the token will be valid both as seconds since 1.1.2009 and as date object.
         * @param tokenObj
         * @returns {*}     promise that resolves with an object that contains how long the token will be valid
         */
        _refreshToken(tokenObj) {
            Debug.Tokens && console.log(this.name, "_refreshToken: " + tokenObj.username + " - Perm: " + this._translPerm(tokenObj.msPermission));
            return this._sendTokenCommand(this._getRefreshCommand(), tokenObj.token, tokenObj.username).depActiveMsThen(function (result) {
                return getLxResponseValue(result);
            }.bind(this), function (err) {
                console.error("Could not refresh the token of '" + tokenObj.username + "' with permission + " + this._translPerm(tokenObj.msPermission));
                console.error(JSON.stringify(err));
                throw err;
            }.bind(this));
        }

        /**
         * Calling refresh on the connection token is dangerous, as if the response isn't received or processed
         * properly (e.g. due to connection problems, closing the app or changing miniservers before the response is
         * received) - an invalid token may be stored for the Miniserver. When receiving "refreshToken" the Miniserver
         * will keep the "old" one alive for about 10 seconds before it's no longer valid.
         *
         * Due to this the connection token should be "re-acquired" and the old one explicitly killed if the new token
         * has been received and stored by the app.
         * @private
         */
        _safeRefreshToken(tokenObj) {
            Debug.Tokens && console.log(this.name, "_safeRefreshToken: " + tokenObj.username + " - Perm: " + this._translPerm(tokenObj.msPermission));

            if (this._isConnectionToken(tokenObj) && Feature.GET_TOKEN_WITH_TOKEN) {
                Debug.Tokens && console.warn(this.name, "   connection token - acquire a new one first, then kill the old one");
                return this._getNewThenKillOldConnectionToken(tokenObj);
            } else {
                if (!Feature.GET_TOKEN_WITH_TOKEN) {
                    Debug.Tokens && console.log(this.name, "   old version - use regular refresh!");
                } else {
                    Debug.Tokens && console.log(this.name, "   not a connection token - use regular refresh!");
                }

                return this._refreshToken(tokenObj);
            }
        }

        /**
         * Using this method at first a new token with the same permissions as the one given is acquired and as soon
         * as it's received, the old token will be killed.
         * This is an alternative to refreshToken which kills the old one right away - which, if the response is not
         * received or processed by the app, may lead to the app having a token which is not valid anymore.
         * @param oldTokenObj
         * @returns {*}
         * @private
         */
        _getNewThenKillOldConnectionToken(oldTokenObj) {
            Debug.Tokens && console.log(this.name, "_getNewThenKillOldConnectionToken");
            return this._getTokenWithPermissionUsingToken({
                requestToken: oldTokenObj
            }).then(function (newToken) {
                Debug.Tokens && console.log(this.name, "   new token received: " + JSON.stringify(newToken));
                Debug.Tokens && console.log(this.name, "   now kill the old one!"); // async, don't wait for the old token to be killed!

                this._killToken(oldTokenObj).then(function () {
                    Debug.Tokens && console.log(this.name, "   old token killed!");
                }.bind(this), function (error) {
                    Debug.Tokens && console.error(this.name, "   failed to kill old token!");
                }.bind(this));

                return newToken;
            }.bind(this), function (error) {
                console.error("Failed to get a new token & kill old one: ", error);
                return Q.reject(error);
            });
        }

        requestTokenWithBearer({ targetHost, bearer, user, msPermission, tls = false }) {
            Debug.Tokens && console.log(this.name, "requestTokenWithBearer: " + targetHost + ", " + this._translPerm(msPermission), user, bearer);
            let cmd,
                resValue,
                tkObj,
                devInfo = getDeviceInfo(),
                devUuid = this._getDeviceUuid(),
                prms;

            if (tls) {
                cmd = Commands.format(Commands.TOKEN.GET_JWT_WITH_BEARER_HEADER, user, msPermission, devUuid, devInfo);
                // pass the token as authorization bearer via the header!
                let headerDict = {
                    Authorization: "Bearer " + bearer
                }
                Debug.Tokens && console.log("   request a token using a bearer-header: " + cmd);
                prms = this.component.sendViaHttpUnauthorized(cmd, targetHost, false, true, null, null, null, null, headerDict)
            } else {
                cmd = Commands.format(Commands.TOKEN.GET_JWT_WITH_BEARER_ARG, user, msPermission, devUuid, devInfo, encodeURIComponent(bearer));
                Debug.Tokens && console.log("   request a token using a bearer-header-arg");
                prms = this.component.sendEncryptedHttpCmdToHost(targetHost, cmd, EncryptionType.REQUEST_RESPONSE_VAL, {automatic: true});
            }

            return prms.then(function (tResult) {
                resValue = getLxResponseValue(tResult);
                Debug.Tokens && console.log("    token received! perm: " + this._translPerm(resValue.tokenRights), tResult);
                tkObj = this._prepareTokenObj(resValue, user);
                return tkObj;
            }.bind(this), function (err) {
                var errObj = {
                    user: user
                };

                try {
                    errObj.code = getLxResponseCode(err);
                    errObj.value = getLxResponseValue(err);
                } catch (ex) {
                    errObj.value = err;
                    errObj.code = 0;
                }

                console.error(this.name, "requestTokenWithBearer: Could not acquire a token! " + JSON.stringify(errObj));
                throw errObj; // forward so the error can be handled on the outside.
            });
        }

        _getTokenWithPermissionUsingToken({
                                              requestToken,
                                              newTokenPermission = requestToken.msPermission
                                          }) {
            Debug.Tokens && console.log(this.name, "_getTokenWithPermissionUsingToken: " + requestToken.username + " - Perm: " + this._translPerm(newTokenPermission));

            var cmd,
                tkObj,
                resValue,
                devInfo = getDeviceInfo(),
                devUuid = this._getDeviceUuid();

            return this.component.getSaltedHash(requestToken.token).then(function (hash) {
                // create the getToken cmd --> but the one that works with a token hash!
                cmd = Commands.format(Commands.TOKEN.GET_JWT_WITH_TOKEN_HASH, hash, requestToken.username, newTokenPermission, devUuid, devInfo);
                Debug.Tokens && console.log("   request a token: " + cmd);
                return this.component.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (tResult) {
                    resValue = getLxResponseValue(tResult);
                    Debug.Tokens && console.log("    token received! perm: " + this._translPerm(resValue.tokenRights));
                    tkObj = this._prepareTokenObj(resValue, requestToken.username);
                    return tkObj;
                }.bind(this), function (err) {
                    var errObj = {
                        user: requestToken.username
                    };

                    try {
                        errObj.code = getLxResponseCode(err);
                        errObj.value = getLxResponseValue(err);
                    } catch (ex) {
                        errObj.value = err;
                        errObj.code = 0;
                    }

                    console.error(this.name, "_getTokenWithPermissionUsingToken: Could not acquire a token! " + JSON.stringify(errObj));
                    throw errObj; // forward so the error can be handled on the outside.
                }.bind(this));
            }.bind(this));
        }

        _checkOrRefreshToken(tokenObj) {
            Debug.Tokens && console.log(this.name, "_checkOrRefreshToken: " + JSON.stringify(tokenObj));

            if (Feature.TOKEN_REFRESH_AND_CHECK) {
                return this._checkToken(tokenObj);
            } else {
                return this._safeRefreshToken(tokenObj);
            }
        }

        /**
         * Will transfer all the attributes needed from the old to the new object & then dispatch it in order to be
         * kept alive, stored, updated inside other components and persisted to the filesystem.
         * @param oldTokenObj
         * @param newTokenObj
         * @private
         */
        _storeNewConnectionToken(oldTokenObj, newTokenObj) {
            Debug.Tokens && console.log(this.name, "_storeNewConnectionToken: " + oldTokenObj.username); // ensure all necessary information is available.

            if (Feature.JWT_SUPPORT) {
                this._applyInfosFromJWT(newTokenObj);
            } else {
                newTokenObj.username = oldTokenObj.username;
                newTokenObj.msPermission = oldTokenObj.msPermission;
                newTokenObj.tokenRights = oldTokenObj.tokenRights;
            } // emit to ensure it is persisted too.


            this.channel.emit(CommunicationComp.ECEvent.TokenReceived, newTokenObj);
        }

        /**
         * Will send a token cmd to the Miniserver (e.g. refresh or kill)
         * @param cmd       the command to send
         * @param token     the token to send it for (will be oneTime-Hashed)
         * @param username  the user for which to send the command.
         * @param addArg0   optional, additional argument for the command
         * @private
         */
        _sendTokenCommand(cmd, token, username, addArg0) {
            (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "_sendTokenCommand: " + cmd);
            var fullCmd;
            return this.component.getSaltedHash(token).then(function (hash) {
                fullCmd = Commands.format(cmd, hash, username, addArg0);
                return this.component.send(fullCmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (result) {
                    (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "   TokenCommand succeeded! " + cmd);
                    return result;
                }.bind(this), function (err) {
                    console.error(this.name, "   TokenCommand failed! " + cmd + ", error: " + JSON.stringify(err), err);
                    throw err; // the error should be handled outside too!
                }.bind(this));
            }.bind(this));
        }

        /**
         * Will return a valid oneTimeSalt when it resolves.
         * @private
         */
        _getOneTimeSalt() {
            return this.component.send(Commands.GET_KEY).then(function (res) {
                return getLxResponseValue(res, true);
            });
        }

        /**
         * Will request a salt object containing both the salt for the user and a oneTimeSalt too.
         * @param user      for whom this salt is to be requested
         * @private
         */
        _requestUserSalt(user) {
            var cmd = Commands.format(Commands.TOKEN.GET_USERSALT, encodeURIComponent(user));
            return this.component.send(cmd, EncryptionType.REQUEST_RESPONSE_VAL).then(function (result) {
                return {
                    oneTimeSalt: result.LL.value.key,
                    salt: result.LL.value.salt,
                    hashAlg: result.LL.value.hashAlg || VendorHub.Crypto.HASH_ALGORITHM.SHA1
                };
            });
        }

        /**
         * Helper method that will create a oneTimeHash (HmacSHA1 or HmacSHA256) of the payload using the oneTimeSalt provided.
         * @param payload       the payload to hash
         * @param oneTimeSalt   the onetime salt to use for the HmacSHA1 or HmacSHA256
         * @param [hashAlg]     The Hash algorithm to use (SHA1 or SHA256), SHA1 is default
         * @returns {string|*}
         * @private
         */
        _otHash(payload, oneTimeSalt, hashAlg) {
            hashAlg = hashAlg || VendorHub.Crypto.HASH_ALGORITHM.SHA1;
            return VendorHub.Crypto["Hmac" + hashAlg](payload, "utf8", oneTimeSalt, "hex", "hex");
        }

        /**
         * Will ensure that the token passed in using the tokenObject will not be invalidated due to a timeout
         * @param tokenObj
         * @param msPermission
         * @private
         */
        _startKeepAlive(tokenObj, msPermission) {
            var expireDate = new LxDate(tokenObj.validUntil, true),
                currDate = SandboxComponent.getMiniserverTimeInfo(null, null, TimeValueFormat.MINISERVER_DATE_TIME), // time here has to be current Miniserver time, otherwise the token will be refreshed too early, too late or constantly in a loop if user manually adjusts his device time.
                lifespan = expireDate - currDate,
                refreshDelay;
            Debug.Tokens && console.log(this.name, "_startKeepAlive: " + this._translPerm(msPermission) + ", prevTo: " + JSON.stringify(tokenObj.kaTimeouts));

            if (lifespan < 0 || !tokenObj.validUntil || isNaN(lifespan)) {
                Debug.Tokens && console.log(this.name, "    token lifespan unknown or negative, try to refresh right away!"); // token lifespan unknown or negative, try to refresh right away!

                lifespan = MIN_DELAY;
            }

            (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "    current: " + ActiveMSComponent.getMiniserverTimeInfo().format(DateType.DateTextAndTime) + " (" + currDate.getSecondsSince2009() * 1000 + ")");
            (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "    expires: " + expireDate.format(DateType.DateTextAndTime) + " (" + tokenObj.validUntil * 1000 + ")"); // if the token is already expired, refresh it right away

            if (!msPermission) {
                msPermission = MsPermission.NONE;
            }

            refreshDelay = this._getRefreshDelay(lifespan, msPermission);
            (Debug.Tokens || Debug.TokenTimeouts) && console.log(this.name, "    refresh in " + LxDate.formatSecondsShort(refreshDelay / 1000, undefined, true));

            if (!tokenObj.kaTimeouts) {
                tokenObj.kaTimeouts = {};
            }

            var cbFn = this._keepAliveFired.bind(this, tokenObj, msPermission);

            var timeout = this._startTimeout(cbFn, refreshDelay, msPermission);

            tokenObj.kaTimeouts[msPermission] = timeout;
            Debug.Tokens && console.log(this.name, "    timeout scheduled: " + JSON.stringify(tokenObj.kaTimeouts));
        }

        /**
         * Computes the delay after which tokens need to be refreshed. Ensures that tokens will be refreshed at least
         * after a day.
         * @param lifespan      how long the token will live in Milliseconds
         * @param permission
         * @return {*}
         * @private
         */
        _getRefreshDelay(lifespan, permission) {
            Debug.TokenTimeouts && console.log(this.name, "_getRefreshDelay");
            var refreshDelay, maxTokenLifespan, minTokenLifespan;

            if (hasBit(permission, MsPermission.APP)) {
                Debug.TokenTimeouts && console.log(this.name, "    app token"); // new app tokens last 8 weeks, the old ones 4

                maxTokenLifespan = Feature.JWT_SUPPORT ? APP_EXTENDED_LIFESPAN : APP_LEGACY_LIFESPAN; // app tokens need to be renewed more often, even if they'd still last for weeks, maybe the Miniserver
                // won't be connected to for quite some time.

                minTokenLifespan = maxTokenLifespan - (Feature.JWT_SUPPORT ? TIMESPAN_WEEK : TIMESPAN_DAY * 2);
            } else {
                Debug.TokenTimeouts && console.log(this.name, "    other token"); // other tokens previously had a lifespan of 30 secs, now they last for an hour.

                maxTokenLifespan = Feature.JWT_SUPPORT ? OTHER_EXTENDED_LIFESPAN : OTHER_LEGACY_LIFESPAN; // as soon as half of their lifespan has passed, renew them (also affects webinterface tokens)

                minTokenLifespan = maxTokenLifespan * MIN_LIFESPAN_RATIO;
            } // when does the token need to be refreshed? ensure its not below 500 ms, if it needs to be refreshed asap


            refreshDelay = Math.max(MIN_DELAY, lifespan - minTokenLifespan); // the interval mustn't exceed the integer max, otherwise it will fire repeatedly - limit it to a week.

            refreshDelay = Math.min(refreshDelay, TIMESPAN_WEEK);
            return refreshDelay;
        }

        _keepAliveFired(tokenObj, msPermission) {
            Debug.Tokens && console.log(this.name, "_keepAliveFired " + JSON.stringify(tokenObj.kaTimeouts));
            delete tokenObj.kaTimeouts[msPermission];

            this._safeRefreshToken(tokenObj).depActiveMsThen(function (res) {
                if (Feature.TOKEN_REFRESH_AND_CHECK) {
                    var oldPermissionKeepAlives = cloneObject(tokenObj.kaTimeouts);

                    if (Object.keys(oldPermissionKeepAlives).length > 0) {
                        console.warn("A token has been refreshed, where other keepAlives are running!");
                        Object.keys(oldPermissionKeepAlives).forEach(function (permissionKeptAlive) {
                            console.warn("    " + tokenObj.kaTimeouts[permissionKeptAlive] + " keepAlive for " + this._translPerm(permissionKeptAlive) + " needs to be stopped & restarted!");
                        }.bind(this));
                    } // ensure the old token is no longer around.


                    this._deleteToken(tokenObj.token); // ensure the new one is properly stored.


                    if (this._isConnectionToken(tokenObj)) {
                        // connection tokens need to be persisted, which is done by the CommComp.
                        this._storeNewConnectionToken(tokenObj, res);
                    } else {
                        res.msPermission = tokenObj.msPermission;
                        res.username = tokenObj.username;

                        this._storeTokenObj(res);

                        this._startKeepAlive(res, msPermission);
                    }
                } else {
                    this._startKeepAlive(tokenObj, msPermission);
                }
            }.bind(this), function (err) {
                this._handleRefreshFailed(tokenObj, err);
            }.bind(this));
        }

        /**
         * Called when the token refresh command has failed.
         * @param tokenObj  the token obj for whom the refresh failed
         * @param err       the error that lead to the failing refresh
         * @private
         */
        _handleRefreshFailed(tokenObj, err) {
            // ensure the token is really invalid and it's not a connection error!
            if (err && err.LL) {
                console.error("Invalid/Outdated token - kill it!");

                this._killToken(tokenObj);
            } else {
                console.warn("Other issue (" + JSON.stringify(err) + ") during token refresh. Don't kill it");
            }
        }

        _startTimeout(cbFn, delay, msPermission) {
            var toUuid = generateUuid();
            var toId = setTimeout(this._timeoutFired.bind(this, cbFn, toUuid, msPermission), delay);
            Debug.TokenTimeouts && console.log(this.name, "_startTimeout: ID: " + toId + ", Perm: " + this._translPerm(msPermission) + ", Delay: " + delay / 1000 + ", " + JSON.stringify(getStackObj()));
            this._toUuids = this._toUuids || {};
            this._toUuids[toUuid] = toId;
            Debug.TokenTimeouts && console.log(this.name, "     " + Object.keys(this._toUuids).length + " timeouts active!");
            return toId;
        }

        _timeoutFired(cbFn, toUuid, msPermission) {
            var toId = this._toUuids[toUuid];
            Debug.TokenTimeouts && console.log(this.name, "_timeoutFired: ID: " + toId + ", Perm: " + this._translPerm(msPermission) + ", " + JSON.stringify(getStackObj()));
            delete this._toUuids[toUuid];
            Debug.TokenTimeouts && console.log(this.name, "     " + Object.keys(this._toUuids).length + " timeouts still active!");
            return cbFn();
        }

        _clearTimeout(toId, msPermission) {
            Debug.TokenTimeouts && console.log("_clearTimeout: ID: " + toId);
            var toUuid = null;
            clearTimeout(toId);
            Object.keys(this._toUuids).forEach(function (currUuid) {
                if (this._toUuids[currUuid] === toId) {
                    toUuid = currUuid;
                }
            }.bind(this));
            Debug.TokenTimeouts && console.log(this.name, "_clearTimeout: ID: " + toId + ", Perm: " + this._translPerm(msPermission) + ", " + JSON.stringify(getStackObj()));

            if (toUuid) {
                delete this._toUuids[toUuid];
            }

            Debug.TokenTimeouts && console.log(this.name, "     " + Object.keys(this._toUuids).length + " timeouts still active!");
        }

        /**
         * Starts or stops the timeout of a specific permission of a token.
         * @param tokenObj      the token whos keepalive is to be stopped
         * @param msPermission  only stop the keepalive of that very permission.
         * @private
         */
        _stopKeepAlive(tokenObj, msPermission) {
            var timeout;

            if (!msPermission) {
                msPermission = MsPermission.NONE;
            }

            Debug.Tokens && console.log(this.name, "_stopKeepAlive: " + this._translPerm(msPermission));

            if (tokenObj.kaTimeouts) {
                timeout = tokenObj.kaTimeouts[msPermission];

                if (!timeout) {
                    Debug.Tokens && console.log(this.name, "   trying to stop timeout that isn't there anymore!");
                }

                timeout && this._clearTimeout(timeout, msPermission);
                delete tokenObj.kaTimeouts[msPermission];
            }
        }

        _stopAllKeepAlives() {
            Debug.Tokens && console.log(this.name, "_stopAllKeepAlives");
            Object.values(this.tokenMap).forEach(this._stopKeepAlivesOfToken.bind(this));
        }

        _stopKeepAlivesOfToken(tokenObj) {
            Debug.Tokens && console.log(this.name, "_stopKeepAlivesOfToken");
            tokenObj.kaTimeouts && Object.values(tokenObj.kaTimeouts).forEach(this._clearTimeout.bind(this));
            tokenObj.kaTimeouts = null;
        }

        /**
         * Ensures the object provided has the minimum set of attributes (token, user, permission), then stores it
         * in the tokenMap.
         * @param tokenObj
         * @return {*|{username: *, token: *, validUntil: *, msPermission: *}}
         * @private
         */
        _storeTokenObj(tokenObj) {
            Debug.Tokens && console.log(this.name, "_storeTokenObj: usr=" + tokenObj.username + ", perm=" + this._translPerm(tokenObj.msPermission)); // ensure the token objects data is okay

            var toStore = this._prepareTokenObj(tokenObj),
                currStored = this.tokenMap[tokenObj.token]; // verify no running keepAlives are "dropped" when the same token is stored again (e.g. after the DL
            // socket authenticated successfully)!


            if (currStored) {
                Debug.Tokens && console.log(this.name, "   would overwrite a token obj, check for running timeouts");

                if (!currStored.kaTimeouts) {
                    Debug.Tokens && console.log(this.name, "     old token has no timeouts to take care of, proceed");
                } else if (!toStore.kaTimeouts) {
                    Debug.Tokens && console.log(this.name, "     new token has no kaTimeouts stored, reuse old ones!");
                    toStore.kaTimeouts = currStored.kaTimeouts;
                } else {
                    Debug.Tokens && console.warn(this.name, "     merge timeouts of old and new together!");
                    Object.keys(currStored.kaTimeouts).forEach(function (perm) {
                        if (toStore.kaTimeouts.hasOwnProperty(perm) && toStore.kaTimeouts[perm] !== currStored.kaTimeouts[perm]) {
                            Debug.Tokens && console.warn(this.name, "       permission " + this._translPerm(perm) + " has a different TO on the old one, clear it!"); // permission part of both tokens, but a different timeout ID stored on them.

                            this._clearTimeout(currStored.kaTimeouts[perm], perm);
                        } else {
                            toStore.kaTimeouts[perm] = currStored.kaTimeouts[perm];
                        }
                    }.bind(this));
                }
            } // Overwrite the username from the miniserver with the username, we requested the token.
            // Needed until Bug BG-I12831 is fixed
            // Otherwise users which are connected via trust are not working correctly
            // Use username from the token as fallback to avoid undefined


            if (!Feature.CHECK_TRUST_USER_TOKENS) {
                toStore.username = this._getOriginalUsername() || toStore.username;
            }

            this.tokenMap[tokenObj.token] = toStore;
            return toStore;
        }

        /**
         * Stores the username with which we requested the token
         * @param username
         * @private
         */
        _setOriginalUsername(username) {
            this._originalUsername = username;
        }

        /**
         * returns the last username where the token request was successful
         * @returns {*}
         * @private
         */
        _getOriginalUsername() {
            return this._originalUsername;
        }

        /**
         * Looks up a token object based on the token itself
         * @param token
         * @returns {*}
         * @private
         */
        _getTokenObj(token) {
            return this.tokenMap[token];
        }

        /**
         * Deletes the token from this extensions dataset (does not kill it or invalidate any timers)
         * @param token
         * @private
         */
        _deleteToken(token) {
            var tokenObj = this.tokenMap[token];

            if (tokenObj && tokenObj.kaTimeouts) {
                if (Object.keys(tokenObj.kaTimeouts).length > 0) {
                    console.error("Deleting a token with active timeouts: ");
                    Object.keys(tokenObj.kaTimeouts).forEach(function (perm) {
                        console.error("   - " + tokenObj.kaTimeouts[perm] + " - " + this._translPerm(perm));
                    }.bind(this));
                }

                delete this.tokenMap[token];
            }
        }

        /**
         * Returns true if the token is currently being kept alive.
         * @param token         the token in question
         * @returns {boolean}
         * @private
         */
        _tokenIsKeptAlive(token) {
            var tokenObj = this._getTokenObj(token);

            return tokenObj && tokenObj.timeout;
        }

        /**
         * Will return a uuid that is based on the platforms UUID.
         * @returns {string}    url encoded device information string
         * @private
         */
        _getDeviceUuid() {
            if (!this.pfUuid) {
                var pfUuid = PlatformComponent.getPlatformInfoObj().uuid;
                this.pfUuid = prepareAsUuid(pfUuid);
            }

            return this.pfUuid;
        }

        /**
         * Will check if the tkObj provided is the one that keeps the socket connection alive.
         * @param tkObj
         * @returns {boolean}
         * @private
         */
        _isConnectionToken(tkObj) {
            var isConnToken = this._hasConnectionPermission(tkObj.msPermission);

            Debug.Tokens && console.log(this.name, "_isConnectionToken: " + isConnToken + ", " + this._translPerm(tkObj.msPermission));
            return isConnToken;
        }

        /**
         * Returns true if the permission provided contains the connection permission.
         * @param msPermission
         * @returns {boolean}
         * @private
         */
        _hasConnectionPermission(msPermission) {
            var res = false;
            res = res || hasBit(msPermission, MsPermission.WEB);
            res = res || hasBit(msPermission, MsPermission.APP);
            return res;
        }

        /**
         * Returns a string containing the userfriendly names of all the different permissions that are set in perm.
         * @param perm
         * @returns {string}
         * @private
         */
        _translPerm(perm) {
            var perms = [];
            Object.keys(MsPermission).forEach(function (key) {
                if (hasBit(perm, MsPermission[key])) {
                    perms.push(key);
                }
            });
            return perms.join(", ");
        }

        /**
         * Calling this method ensures that the token object that is being returned contains all data needed for a
         * token. It will not reuse any other data provided by the input object that is not needed for a tokenObj.
         * @param inputObj      the input obj (e.g. as returned by the Miniserver)
         * @param [username]    optional, may also be contained in the inputObj.
         * @returns {{ username: *, token: *, validUntil: *, msPermission: * }}
         * @private
         */
        _prepareTokenObj(inputObj, username) {
            var tkObj = inputObj;

            if (Feature.JWT_SUPPORT) {
                this._applyInfosFromJWT(tkObj);
            } else {
                tkObj.username = inputObj.username ? inputObj.username : username;
                tkObj.msPermission = inputObj.msPermission ? inputObj.msPermission : inputObj.tokenRights;
            }

            if (!tkObj.token || !("msPermission" in tkObj) || !tkObj.username) {
                throw new Error("This token object is not valid!");
            }

            return tkObj;
        }

        _applyInfosFromJWT(tokenObj) {
            Debug.Tokens && console.log(this.name, "_applyInfosFromJWT");
            var jwt = parseJwt(tokenObj.token);
            tokenObj.jwt = jwt;
            tokenObj.username = jwt.payload.user || tokenObj.username || this._getOriginalUsername();
            tokenObj.msPermission = jwt.payload.tokenRights;
            tokenObj.tokenRights = jwt.payload.tokenRights;
            tokenObj.initializedAtJwt = new LxDate(moment.unix(jwt.payload.iat), false).getSecondsSince2009();
            tokenObj.validUntilJwt = new LxDate(moment.unix(jwt.payload.exp), false).getSecondsSince2009();
            tokenObj.strInitializedAt = new LxDate(moment.unix(jwt.payload.iat), false).format(DateType.DateTextAndTime);
            tokenObj.strValidUntil = new LxDate(moment.unix(jwt.payload.exp), false).format(DateType.DateTextAndTime);
            Debug.Tokens && console.log(this.name, "   ", cloneObject(tokenObj));
        }

        /**
         * Returns the right getToken command, as new Miniservers support a separate command for JWT & legacy tokens
         * @return {string}
         * @private
         */
        _getGetTokenCommand() {
            return Feature.JWT_SUPPORT ? Commands.TOKEN.GET_JWT_TOKEN : Commands.TOKEN.GET_TOKEN;
        }

        /**
         * Returns the proper refresh command, as new Miniservers support a separate command for JWT & legacy tokens
         * @return {string}
         * @private
         */
        _getRefreshCommand() {
            return Feature.JWT_SUPPORT ? Commands.TOKEN.REFRESH_JWT : Commands.TOKEN.REFRESH;
        }

    };
});
