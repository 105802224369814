'use strict';

import Root_CA from '../../resources/misc/root_ca.pem';

window.UtilityComp.service('ActiveMSCommunicationService', function () {
    var commService = {};
    return commService;
});

/**
 * Active Miniserver Component represent the current Miniserver which is active in the App.
 * @param CommunicationComp instance of the CommunicationComponent
 * @param PersistenceComp instance of the PersistenceComp
 */
export default ({names}) => {
    window[names.int].factory(names.int, ['$injector', 'ActiveMSCommunicationService', 'PersistenceComp', function ($injector, ActiveMSCommunicationService, PersistenceComp) {
        // internal variables

        let weakThis,
            msInfoExt,
            structureExt,
            userSortingExt,
            addonsExt,
            homeKitAddonExt,
            alexaAddonExt,
            timeExt,
            userManagementExt,
            securedDetailsExt,
            operatingModeExt,
            expertModeExt,
            searchExt,
            messageCenterExt,
            deviceLearningExt,
            trustExt,
            extension = {},
            structureManagerObj,
            activeMiniserverObj,
            currentUrl,
            reachMode,
            sessionNumber = 0,
            // increasing number per session to bail out older async requests
            structureReadyTimer,
            _structureReadyReg,
            _allStatesReceivedReg,
            _comReadyDef = Q.defer();

        /**
         * c-tor for Active Miniserver Component
         * @returns exposed functions for other components
         * @constructor
         */


        function ActMsComp() {
            weakThis = this
            this.name = "ActiveMsComp"; // storing a reference to all extensions

            msInfoExt = new extension.MSInformation(this);
            structureExt = new extension.Structure(this);
            userSortingExt = new extension.UserSorting(this);
            addonsExt = new extension.Addons(this);
            homeKitAddonExt = new extension.HomeKitAddon(this);
            alexaAddonExt = new extension.AlexaAddon(this);
            timeExt = new extension.Time(this);
            userManagementExt = new extension.UserManagement(this);
            securedDetailsExt = new extension.SecuredDetails(this);
            operatingModeExt = new extension.OperatingMode(this);
            expertModeExt = new extension.ExpertMode(this);
            searchExt = new extension.Search(this);
            messageCenterExt = new extension.MessageCenter(this);
            deviceLearningExt = new extension.DeviceLearning(this);
            trustExt = new extension.Trust(this);
            structureManagerObj = buildStructureManager(); // external broadcasts

            CompChannel.on(CCEvent.StartMSSession, function (event, miniserverObj) {
                sessionNumber++; // invalidate, we started a new session
                // also set the object immediately here,
                // because if the user quickly closes the app after trying to connect, we don't have a miniserver to go on in "resume" otherwise

                Debug.MSSession && console.log(weakThis.name, "OnStartMsSession " + _logMs(miniserverObj));
                activeMiniserverObj = miniserverObj;
                msInfoExt.setTemporarilyStructureInfos(miniserverObj);
                weakThis.emit(ActiveMSComp.ECEvent.StartMSSession);
            });
            CompChannel.on(CCEvent.ConnEstablished, function (event, miniserverObj, rm, url) {
                Debug.MSSession && console.log(weakThis.name, "OnConnEstablished " + _logMs(miniserverObj));
                activeMiniserverObj = miniserverObj;
                reachMode = rm;
                currentUrl = url;
                msInfoExt.setTemporarilyStructureInfos(miniserverObj);

                if (miniserverObj.serialNo) {
                    // load imageBox immediately from the FS (otherwise the GUI requests images and the imageBox isn't loaded yet from the FS!)
                    ImageBox.setMSInformation(miniserverObj.serialNo);
                } // connection was successfully, set the last connected date


                miniserverObj.lastConnectedDate = moment().unix();
                weakThis.emit(ActiveMSComp.ECEvent.ConnEstablished);

                weakThis._detectAppReady();
            });
            CompChannel.on(CCEvent.StructureReady, function () {
                ImageBox.setMSInformation(activeMiniserverObj.serialNo);
                searchExt.updateIndex();

                _comReadyDef.resolve();
            });
            CompChannel.on(CCEvent.ConnClosed, function () {
                sessionNumber++; // invalidate, we will establish a new connection soon (eg. structure downloads must be invalidated..)

                if (activeMiniserverObj) {
                    // connection was successfully, set the last connected date
                    // is needed to increase the retry timeout when 401 happens - means that we where recently (last 5min) connected
                    activeMiniserverObj.lastConnectedDate = moment().unix();
                }

                weakThis.emit(ActiveMSComp.ECEvent.ConnClosed);
                _comReadyDef = Q.defer();
            });
            CompChannel.on(CCEvent.StopMSSession, function () {
                sessionNumber++; // invalidate, we disconnected from this Miniserver permanently

                if (activeMiniserverObj && activeMiniserverObj.serialNo) {
                    ImageBox.saveMSImageBox(true);
                }

                Debug.MSSession && console.log(weakThis.name, "OnStopMSSession " + _logMs(activeMiniserverObj));
                activeMiniserverObj = null;
                currentUrl = null;
                weakThis.emit(ActiveMSComp.ECEvent.StopMSSession);
            });
            CompChannel.on(CCEvent.STRUCTURE_UPDATE, function (event, updatePacket) {
                weakThis.emit(ActiveMSComp.ECEvent.STRUCTURE_UPDATE, updatePacket);
            }); // Just update the search index to find the newly created or updated controls in the app search

            CompChannel.on(CCEvent.STRUCTURE_UPDATE_FINISHED, function (event, newControls) {
                searchExt.updateIndex();
                QuickActionUtility.verifyQuickActions();
            });
            CompChannel.on(CCEvent.REACHABILITY_UPDATE, function (event, reachMode) {
                weakThis.emit(ActiveMSComp.ECEvent.REACHABILITY_UPDATE, reachMode);
            });
            CompChannel.on(CCEvent.StateContainersCreated, function (event) {
                weakThis.emit(CCEvent.StateContainersCreated);
            });
            weakThis.on(ActiveMSComp.ECEvent.AppInitInfoReady, function (event, info) {
                CompChannel.emit(CCEvent.AppInitInfoReady, info);
            }); // make methods of extensions publicly available

            var exposed = {
                get comReadyPrms() {
                    return _comReadyDef.promise;
                },

                getActiveMiniserver: getActiveMiniserver,
                getActiveMiniserverSerial: getActiveMiniserverSerial,
                getStructureManager: function getStructureManager() {
                    return structureManagerObj;
                },
                getImageByNrOrUuid: weakThis.getImageByNrOrUuid,
                getImage: weakThis.getImage,
                setConfigVersion: msInfoExt.setConfigVersion,
                setCurrentUserName: msInfoExt.setCurrentUserName,
                getCurrentUsername: weakThis.getCurrentUsername,
                getConfigVersion: msInfoExt.getConfigVersion,
                hasHomeKitToken: msInfoExt.hasHomeKitToken,
                hasRequiredConfigVersion: weakThis.hasRequiredConfigVersion,
                getCurrentUrl: weakThis.getCurrentUrl.bind(weakThis),
                getCurrentCredentials: weakThis.getCurrentCredentials,
                hasSecureMSCredentials: weakThis.hasSecureMSCredentials,
                getBrandingDate: msInfoExt.getBrandingDate,
                getDeviceMonitorUuid: msInfoExt.getDeviceMonitorUuid,
                getLocalUrl: msInfoExt.getLocalUrl,
                getRemoteUrl: msInfoExt.getRemoteUrl,
                getTempUnit: msInfoExt.getTempUnit,
                getSquareMeasure: msInfoExt.getSquareMeasure,
                getCurrencyString: msInfoExt.getCurrencyString,
                getCurrencyFormat: this.getCurrencyFormat,
                getMiniserverName: msInfoExt.getMiniserverName,
                getMiniserverLocation: msInfoExt.getMiniserverLocation,
                setMiniserverSerialNo: weakThis.setMiniserverSerialNo,
                getMiniserverSerialNo: msInfoExt.getMiniserverSerialNo,
                getMiniserverType: msInfoExt.getMiniserverType,
                getGatewayType: msInfoExt.getGatewayType,
                getStructureDate: weakThis.getStructureDate,
                setMiniserverConnectionUrl: weakThis.setMiniserverConnectionUrl,
                getMiniserverConnectionUrl: weakThis.getMiniserverConnectionUrl,
                setStructureDate: weakThis.setStructureDate,
                isAdminUser: msInfoExt.isAdminUser,
                getTlsInfo: msInfoExt.getTlsInfo,
                getDataCenter: weakThis.getDataCenter.bind(weakThis),
                setTlsInfo: weakThis.setTlsInfo,
                setIsInTrust: weakThis.setIsInTrust,
                setDataCenter: weakThis.setDataCenter,
                isUserAllowedToChangePW: weakThis.isUserAllowedToChangePW,
                getAvailablePermissions: msInfoExt.getAvailablePermissions,
                getCurrentSessionCheck: weakThis.getCurrentSessionCheck,
                getDeviceFavoritesActive: weakThis.getDeviceFavoritesActive.bind(weakThis),
                getDeviceFavoriteSettings: weakThis.getDeviceFavoriteSettings.bind(weakThis),
                getDeviceFavoriteSettingsFor: weakThis.getDeviceFavoriteSettingsFor.bind(weakThis),
                setDeviceFavoriteSettingsFor: weakThis.setDeviceFavoriteSettingsFor.bind(weakThis),
                getPartnerName: msInfoExt.getPartnerName,
                getPartnerImageUUID: msInfoExt.getPartnerImageUUID,
                getPartnerDetails: msInfoExt.getPartnerDetails,
                getOriginalRemoteUrl: msInfoExt.getOriginalRemoteUrl,
                getMiniserverTimeInfo: timeExt.getMiniserverTimeInfo,
                getMiniserverTimeAsFakeUTC: timeExt.getMiniserverTimeAsFakeUTC,
                getStartAndEndOfAsUnixUtcTimestamp: timeExt.getStartAndEndOfAsUnixUtcTimestamp.bind(timeExt),
                getMomentFromUtcTimestamp: timeExt.getMomentFromUtcTimestamp.bind(timeExt),
                isUnixUtcTsWithinCurrentRange: timeExt.isUnixUtcTsWithinCurrentRange.bind(timeExt),
                getMiniserverUnixUtcTimestamp: timeExt.getMiniserverUnixUtcTimestamp.bind(timeExt),
                removeFromTimeInfo: timeExt.removeFromTimeInfo,
                convertToMsDate: timeExt.convertToMsDate,
                // converts a javascript date object to a moment.js obj in Miniserver TZ
                convertToJsDate: timeExt.convertToJsDate,
                // converts a moment.js object in Miniserver Timezone to a Javascript Date object.
                // User Management
                //getUsergroups: userManagementExt.getUsergroups,
                //createUsergroup: userManagementExt.createUsergroup,
                //updateUsergroup: userManagementExt.updateUsergroup,
                //deleteUsergroup: userManagementExt.deleteUsergroup,
                getUsers: userManagementExt.getUsers.bind(userManagementExt),
                getTrustPeers: userManagementExt.getTrustPeers.bind(userManagementExt),
                getUsersFromPeer: userManagementExt.getUsersFromPeer.bind(userManagementExt),
                editPeerUsers: userManagementExt.editPeerUsers.bind(userManagementExt),
                getCurrentUser: userManagementExt.getCurrentUser.bind(userManagementExt),
                getTrustManager: userManagementExt.getTrustManager.bind(userManagementExt),
                getUserCustomFieldCaptions: userManagementExt.getCustomFieldCaptions.bind(userManagementExt),
                getUserPropertyOptions: userManagementExt.getUserPropertyOptions.bind(userManagementExt),
                getUserFromUniqueId: userManagementExt.getUserFromUniqueId.bind(userManagementExt),
                //createUser: userManagementExt.createUser,
                //updateUser: userManagementExt.updateUser,
                //deleteUser: userManagementExt.deleteUser,
                changePassword: userManagementExt.changePassword.bind(userManagementExt),
                changeVisuPassword: userManagementExt.changeVisuPassword.bind(userManagementExt),
                changeAccessCode: userManagementExt.changeAccessCode.bind(userManagementExt),
                addNfcTag: userManagementExt.addNfcTag.bind(userManagementExt),
                removeNfcTag: userManagementExt.removeNfcTag.bind(userManagementExt),
                getGroupList: userManagementExt.getGroupList.bind(userManagementExt),
                deleteUser: userManagementExt.deleteUser.bind(userManagementExt),
                addOrEditUser: userManagementExt.addOrEditUser.bind(userManagementExt),
                getUser: userManagementExt.getUser.bind(userManagementExt),
                getUserStateDescription: userManagementExt.getUserStateDescription.bind(userManagementExt),
                getPasswordStrength: userManagementExt.getPasswordStrength.bind(userManagementExt),
                getPasswordCell: userManagementExt.getPasswordCell.bind(userManagementExt),
                sortUsers: userManagementExt.sortUsers.bind(userManagementExt),
                getDatePickerCell: userManagementExt.getDatePickerCell.bind(userManagementExt),
                checkUserStateTimes: userManagementExt.checkUserStateTimes.bind(userManagementExt),
                getRequiredUserManagementPermissionInfos: userManagementExt.getRequiredUserManagementPermissionInfos.bind(userManagementExt),
                getAdminUserGroupType: userManagementExt.getAdminUserGroupType.bind(userManagementExt),
                getCustomGroupTitles: weakThis.getCustomGroupTitles,
                getControlByUUID: structureExt.getControlByUUID,
                getSortingStructure: userSortingExt.getSortingStructure.bind(userSortingExt),
                createNewSortingStructure: userSortingExt.createNewSortingStructure.bind(userSortingExt),
                setCurrentSortingMode: userSortingExt.setCurrentSortingMode.bind(userSortingExt),
                getCurrentSortingMode: userSortingExt.getCurrentSortingMode.bind(userSortingExt),
                getSortingReadyPromise: userSortingExt.getSortingReadyPromise.bind(userSortingExt),
                getCategoryFor: userSortingExt.getCategoryFor.bind(userSortingExt),
                getRoomFor: userSortingExt.getRoomFor.bind(userSortingExt),
                createSortingStructureForTab: userSortingExt.createSortingStructureForTab.bind(userSortingExt),
                setSortingStructureForObject: userSortingExt.setSortingStructureForObject.bind(userSortingExt),
                updateSharedData: userSortingExt.updateSharedData.bind(userSortingExt),
                resetCompleteStructure: userSortingExt.resetCompleteStructure.bind(userSortingExt),
                resetUserAndDeviceNewSorting: userSortingExt.resetUserAndDeviceNewSorting.bind(userSortingExt),
                removeSortingStructureForObject: userSortingExt.removeSortingStructureForObject.bind(userSortingExt),
                removeFavorite: userSortingExt.removeFavorite.bind(userSortingExt),
                addFavorite: userSortingExt.addFavorite.bind(userSortingExt),
                getAddonList: addonsExt.getAddonList.bind(addonsExt),
                sendAddonCommand: addonsExt.sendAddonCommand.bind(addonsExt),
                sendHttpAddonCommand: addonsExt.sendHttpAddonCommand.bind(addonsExt),
                deleteAddonInstance: addonsExt.deleteAddonInstance.bind(addonsExt),
                createAddonInstance: addonsExt.createAddonInstance.bind(addonsExt),
                ensureHomeKitToken: homeKitAddonExt.ensureHomeKitToken.bind(homeKitAddonExt),
                doesActiveMiniserverSupportHomeKit: homeKitAddonExt.doesActiveMiniserverSupportHomeKit.bind(homeKitAddonExt),
                HomeKitEnum: homeKitAddonExt.constructor.Enums,
                isHomeKitAddonCreated: homeKitAddonExt.isHomeKitAddonCreated.bind(homeKitAddonExt),
                isAlexaAddonCreated: alexaAddonExt.isAlexaAddonCreated.bind(alexaAddonExt),
                createAlexaPlugin: alexaAddonExt.createAlexaPlugin.bind(alexaAddonExt),
                deleteAlexaPlugin: alexaAddonExt.deleteAlexaPlugin.bind(alexaAddonExt),
                startAuthFlow: alexaAddonExt.startAuthFlow.bind(alexaAddonExt),
                sendConfigurationComplete: alexaAddonExt.sendConfigurationComplete.bind(alexaAddonExt),
                loginAmazonUser: alexaAddonExt.loginAmazonUser.bind(alexaAddonExt),
                getAuthState: alexaAddonExt.getAuthState.bind(alexaAddonExt),
                setAlexaAPIUser: alexaAddonExt.setAlexaAPIUser.bind(alexaAddonExt),
                getEndpoints: alexaAddonExt.getEndpoints.bind(alexaAddonExt),
                getAlexaUserMail: alexaAddonExt.getUserMail.bind(alexaAddonExt),
                updateAllEndpoints: alexaAddonExt.updateAllEndpoints.bind(alexaAddonExt),
                updateEndpoint: alexaAddonExt.updateEndpoint.bind(alexaAddonExt),
                sendUpdateEndpoints: alexaAddonExt.sendUpdateEndpoints.bind(alexaAddonExt),
                getAlexaAddonVersion: alexaAddonExt.getAlexaAddonVersion.bind(alexaAddonExt),
                checkPluginConfiguredState: alexaAddonExt.checkPluginConfiguredState.bind(alexaAddonExt),
                logoutAlexaUser: alexaAddonExt.logoutUser.bind(alexaAddonExt),
                alexaClearRequestTimeouts: alexaAddonExt.clearRequestTimeouts.bind(alexaAddonExt),
                alexaGetControlNameForType: alexaAddonExt.getControlNameForType.bind(alexaAddonExt),
                getLocalEndpoints: alexaAddonExt.getLocalEndpoints.bind(alexaAddonExt),
                getHomeKitAddonStatus: homeKitAddonExt.getHomeKitAddonStatus.bind(homeKitAddonExt),
                getHomeKitAddonOwner: homeKitAddonExt.getHomeKitAddonOwner.bind(homeKitAddonExt),
                homeKitAddonUpdateRequired: homeKitAddonExt.homeKitAddonUpdateRequired.bind(homeKitAddonExt),
                checkHomeKitPermission: homeKitAddonExt.checkHomeKitPermission.bind(homeKitAddonExt),
                getMiniserverHomeName: homeKitAddonExt.getMiniserverHomeName.bind(homeKitAddonExt),
                assignNewHomeKitAccessories: homeKitAddonExt.assignNewHomeKitAccessories.bind(homeKitAddonExt),
                getHomeKitPairingInfo: homeKitAddonExt.getHomeKitPairingInfo.bind(homeKitAddonExt),
                getHomeKitAccessoriesList: homeKitAddonExt.getHomeKitAccessoriesList.bind(homeKitAddonExt),
                deleteHomeKitPlugin: homeKitAddonExt.deleteHomeKitPlugin.bind(homeKitAddonExt),
                createHomeKitPlugin: homeKitAddonExt.createHomeKitPlugin.bind(homeKitAddonExt),
                stopHomeKitPolling: homeKitAddonExt.stopHomeKitPolling.bind(homeKitAddonExt),
                resetHomeKitSetupStage: homeKitAddonExt.resetHomeKitSetupStage.bind(homeKitAddonExt),
                getMaxHomeKitAccessories: homeKitAddonExt.getMaxHomeKitAccessories.bind(homeKitAddonExt),
                setHomeKitUser: homeKitAddonExt.setHomeKitUser.bind(homeKitAddonExt),
                openIosAppSettings: homeKitAddonExt.openIosAppSettings.bind(homeKitAddonExt),
                getCurrentHomeKitUserType: homeKitAddonExt.getCurrentHomeKitUserType.bind(homeKitAddonExt),
                unpairHomeKit: homeKitAddonExt.unpairHomeKit.bind(homeKitAddonExt),
                updateHomeKitAccessoriesList: homeKitAddonExt.updateHomeKitAccessoriesList.bind(homeKitAddonExt),
                pairMiniserverWithHomeKit: homeKitAddonExt.pairMiniserverWithHomeKit.bind(homeKitAddonExt),
                getAllHomeKitHomes: homeKitAddonExt.getAllHomeKitHomes.bind(homeKitAddonExt),
                getHomeKitAccessoriesWithRooms: homeKitAddonExt.getHomeKitAccessoriesWithRooms.bind(homeKitAddonExt),
                assignHomeKitAccessory: homeKitAddonExt.assignHomeKitAccessory.bind(homeKitAddonExt),
                resetHomeKit: homeKitAddonExt.resetHomeKit.bind(homeKitAddonExt),
                identifyHomeKitAccessory: homeKitAddonExt.identifyHomeKitAccessory.bind(homeKitAddonExt),
                assignHomeKitAccessories: homeKitAddonExt.assignHomeKitAccessories.bind(homeKitAddonExt),
                changeHomeKitAccessoryServiceType: homeKitAddonExt.changeHomeKitAccessoryServiceType.bind(homeKitAddonExt),
                getHomeKitPairingQRCode: homeKitAddonExt.getHomeKitPairingQRCode.bind(homeKitAddonExt),
                addHomeKitHome: homeKitAddonExt.addHomeKitHome.bind(homeKitAddonExt),
                getSortingStructureForObject: userSortingExt.getSortingStructureForObject,
                getPresenceRoom: weakThis.getPresenceRoom,
                setEcoDarkenerActive: weakThis.setEcoDarkenerActive,
                resetAmbientToDefaultLocation: weakThis.resetAmbientToDefaultLocation,
                getPublicKey: weakThis.getPublicKey.bind(weakThis),
                resetPublicKey: weakThis.resetPublicKey,
                logOffUser: weakThis.logOffUser,
                disconnectMiniserver: weakThis.disconnectMiniserver,
                verifyPassword: weakThis.verifyPassword,
                getSecuredDetailsFor: securedDetailsExt.getSecuredDetailsFor.bind(securedDetailsExt),
                loadSecuredDetailsFor: securedDetailsExt.loadSecuredDetailsFor.bind(securedDetailsExt),
                mergeSecuredDetailsWithControl: securedDetailsExt.mergeSecuredDetailsWithControl.bind(securedDetailsExt),
                resetSecuredDetails: securedDetailsExt.resetSecuredDetails.bind(securedDetailsExt),
                registerForSecuredDetails: securedDetailsExt.registerForSecuredDetails.bind(securedDetailsExt),
                unregisterFromSecuredDetails: securedDetailsExt.unregisterFromSecuredDetails.bind(securedDetailsExt),
                getSchedules: operatingModeExt.getSchedules.bind(operatingModeExt),
                deleteOpModeEntry: operatingModeExt.deleteEntry.bind(operatingModeExt),
                saveOpModeEntry: operatingModeExt.saveEntry.bind(operatingModeExt),
                getHeatCoolPeriodEntries: operatingModeExt.getHeatCoolPeriodEntries.bind(operatingModeExt),
                updateToLatestRelease: weakThis.updateToLatestRelease,
                // admin only
                getHeatCoolPeriodSpans: operatingModeExt.getHeatCoolPeriodSpans.bind(operatingModeExt),
                // for all users
                searchFor: searchExt.searchFor.bind(searchExt),
                updateSearchIndex: searchExt.updateIndex.bind(searchExt),
                getSearchIndexSize: searchExt.getIndexSize.bind(searchExt),
                // ExpertMode
                isExpertModeEnabled: expertModeExt.isExpertModeEnabled.bind(expertModeExt),
                isExpertModeLightEnabled: expertModeExt.isExpertModeLightEnabled.bind(expertModeExt),
                getSettingsConfigForObject: expertModeExt.getSettingsConfigForObject.bind(expertModeExt),
                getSettingsFieldForObject: expertModeExt.getSettingsFieldForObject.bind(expertModeExt),
                getSettingsFieldFromObject: expertModeExt.getSettingsFieldFromObject.bind(expertModeExt),
                createRoom: expertModeExt.createRoom.bind(expertModeExt),
                getLightSettingsConfigForObject: expertModeExt.getLightSettingsConfigForObject.bind(expertModeExt),
                verifySettingForObject: expertModeExt.verifySettingForObject.bind(expertModeExt),
                saveSettingsForObject: expertModeExt.saveSettingsForObject.bind(expertModeExt),
                sendExpertModeRefresh: expertModeExt.sendRefresh.bind(expertModeExt),
                // MessageCenter
                registerForMessageCenterUpdate: messageCenterExt.registerForMessageCenterUpdate.bind(messageCenterExt),
                registerSourceUuidForMessageCenterUpdate: messageCenterExt.registerSourceUuidForMessageCenterUpdate.bind(messageCenterExt),
                fetchMessageCenterStructure: messageCenterExt.fetchEntries.bind(messageCenterExt),
                hasMessageCenterStructure: messageCenterExt.hasMessageCenterStructure.bind(messageCenterExt),
                getActiveMessageCenterEntry: messageCenterExt.getActiveEntry.bind(messageCenterExt),
                updateMessageCenterEntry: messageCenterExt.updateEntry.bind(messageCenterExt),
                // DeviceLearning
                switchToSearchableChild: deviceLearningExt.switchToSearchableChild.bind(deviceLearningExt),
                switchToSearchableParent: deviceLearningExt.switchToSearchableParent.bind(deviceLearningExt),
                getRequiredDeviceSearchPermissions: deviceLearningExt.getRequiredDeviceSearchPermissions.bind(deviceLearningExt),
                getDeviceLocation: deviceLearningExt.getDeviceLocation.bind(deviceLearningExt),
                getDeviceMountingLocation: deviceLearningExt.getDeviceMountingLocation.bind(deviceLearningExt),
                getExtensionName: deviceLearningExt.getExtensionName.bind(deviceLearningExt),
                getMaxNumDevices: deviceLearningExt.getMaxNumDevices.bind(deviceLearningExt),
                deviceTypeSupportsNumberedName: deviceLearningExt.deviceTypeSupportsNumberedName.bind(deviceLearningExt),
                removeOtherDevices: deviceLearningExt.removeOtherDevices.bind(deviceLearningExt),
                loadRoomFileFromLocalStorage: deviceLearningExt.loadRoomFileFromLocalStorage.bind(deviceLearningExt),
                startDeviceSearch: deviceLearningExt.startSearch.bind(deviceLearningExt),
                restartDeviceSearch: deviceLearningExt.restartSearch.bind(deviceLearningExt),
                stopDeviceSearch: deviceLearningExt.stopSearch.bind(deviceLearningExt),
                getDeviceSearchingState: deviceLearningExt.getSearchingState.bind(deviceLearningExt),
                stopDeviceSearchKeepalive: deviceLearningExt.stopKeepalive.bind(deviceLearningExt),
                startDeviceSearchKeepalive: deviceLearningExt.startKeepalive.bind(deviceLearningExt),
                getAvailableExtensions: deviceLearningExt.getAvailableExtensions.bind(deviceLearningExt),
                getAvailableMiniservers: deviceLearningExt.getAvailableMiniservers.bind(deviceLearningExt),
                getAvailableTechTags: deviceLearningExt.getAvailableTechTags.bind(deviceLearningExt),
                registerForExtensionChanges: deviceLearningExt.registerForExtensionChanges.bind(deviceLearningExt),
                registerForTechTagChanges: deviceLearningExt.registerForTechTagChanges.bind(deviceLearningExt),
                getDevicesOfExtensions: deviceLearningExt.getDevicesOfExtensions.bind(deviceLearningExt),
                getExtension: deviceLearningExt.getExtension.bind(deviceLearningExt),
                getDeviceSearchResultsFromMS: deviceLearningExt.getResultsFromMS.bind(deviceLearningExt),
                stopDeviceIdentify: deviceLearningExt.stopDeviceIdentify.bind(deviceLearningExt),
                setDeviceIdentify: deviceLearningExt.setDeviceIdentify.bind(deviceLearningExt),
                startLightGroupIdentify: deviceLearningExt.startLightGroupIdentify.bind(deviceLearningExt),
                stopLightGroupIdentify: deviceLearningExt.stopLightGroupIdentify.bind(deviceLearningExt),
                createNewDevice: deviceLearningExt.createNewDevice.bind(deviceLearningExt),
                replaceDevice: deviceLearningExt.replaceDevice.bind(deviceLearningExt),
                createLightGroup: deviceLearningExt.createLightGroup.bind(deviceLearningExt),
                getAvailableLightGroups: deviceLearningExt.getAvailableLightGroups.bind(deviceLearningExt),
                registerForLightGroupChanges: deviceLearningExt.registerForLightGroupChanges.bind(deviceLearningExt),
                identifySwitchboardType: deviceLearningExt.identifySwitchboardType.bind(deviceLearningExt),
                createSwitchboard: deviceLearningExt.createSwitchboard.bind(deviceLearningExt),
                getAvailableSwitchboards: deviceLearningExt.getAvailableSwitchboards.bind(deviceLearningExt),
                registerForSwitchboardChanges: deviceLearningExt.registerForSwitchboardChanges.bind(deviceLearningExt),
                getLastPairedDevicSerial: deviceLearningExt.getLastPairedDevicSerial.bind(deviceLearningExt),
                registerForDeviceLearningStateChange: deviceLearningExt.registerForStateChange.bind(deviceLearningExt),
                unregisterForDeviceLearningStateChange: deviceLearningExt.unregisterStateChange.bind(deviceLearningExt),
                getDeviceSearchCurrentDeviceList: deviceLearningExt.getCurrentDeviceList.bind(deviceLearningExt),
                getImageUrlWithDeviceType: deviceLearningExt.getImageUrlWithDeviceType.bind(deviceLearningExt),
                getImageUrlForMiniserverType: deviceLearningExt.getImageUrlForMiniserverType.bind(deviceLearningExt),
                registerForDeviceLearningCounterStateChange: deviceLearningExt.registerForDeviceCounterChangedNotify.bind(deviceLearningExt),
                registerForDeviceLearningSearchStateChange: deviceLearningExt.registerForSearchingStateChangedNotify.bind(deviceLearningExt),
                registerForDeviceSearch: deviceLearningExt.registerForDeviceSearch.bind(deviceLearningExt),
                getDevicesJSON: deviceLearningExt.getDevicesJSON.bind(deviceLearningExt),
                prepareForDeviceSearch: deviceLearningExt.prepareForDeviceSearch.bind(deviceLearningExt),
                getDeviceIdentifier: deviceLearningExt.getDeviceIdentifier.bind(deviceLearningExt),
                isSupportedExtensionType: deviceLearningExt.isSupportedExtensionType.bind(deviceLearningExt),
                setDeviceLearningImageObj: deviceLearningExt.setImageObj.bind(deviceLearningExt),
                setSearchType: deviceLearningExt.setSearchType.bind(deviceLearningExt),
                isValidDeviceSearchType: deviceLearningExt.isValidDeviceSearchType.bind(deviceLearningExt),
                getSearchType: deviceLearningExt.getSearchType.bind(deviceLearningExt),
                getTitleForDeviceSearchType: deviceLearningExt.getTitleForSearchType.bind(deviceLearningExt),
                canSearchOverAllExtensions: deviceLearningExt.canSearchOverAllExtensions.bind(deviceLearningExt),
                canShowFoundDevices: deviceLearningExt.canShowFoundDevices.bind(deviceLearningExt),

                registerForTrustStructure: trustExt.registerForStructure.bind(trustExt),
                getTrustedMiniserverList: trustExt.getMiniserverList.bind(trustExt),
                switchToTrustedMiniserver: trustExt.switchToMiniserver.bind(trustExt),
                showTrustedMiniserverInfo: trustExt.showMiniserverInfo.bind(trustExt),
                deleteTrustStructure: trustExt.deleteTrustStructure.bind(trustExt)
            };
            return exposed;
        }

        BaseComponent.beInheritedBy(ActMsComp);
        extension = BaseComponent.initExtensions(names.int, $injector);/**

         * Will wait for both StructureReady & AllStatesReady (or a 10 seconds timeout after structure ready) and then
         * it'll emit AppReady on the CC.
         * @private
         */

        ActMsComp.prototype._detectAppReady = function _detectAppReady() {
            Debug.CrucialDataLoaded && console.log(this.name, "_detectAppReady");
            var structureDef = getDeferredOfCCEvent(CCEvent.StructureReady),
                statesDef = getDeferredOfCCEvent(CCEvent.ALL_STATES_RECEIVED),
                connCloseDef = getDeferredOfCCEvent(CCEvent.ConnClosed),
                statesTimeoutDef = null,
                statesTimeoutMax = 10 * 1000,
                promises = [structureDef.promise, statesDef.promise],
                delayTimeoutDef = null,
                delayTimeoutMax = 2 * 1000; // when the structure is ready & states aren't ready, start a timeout, we'll wait at most 10 seconds for states.
            // if after 10 seconds the states aren't ready, they will probably never arrive (e.g. gateway client offline)

            structureDef.promise.then(function () {
                Debug.CrucialDataLoaded && console.log(this.name, "_detectAppReady - structure ready");
                var prms;

                if (statesDef && statesDef.promise.isPending()) {
                    Debug.CrucialDataLoaded && console.log(this.name, "    AppReady, start timeout for states");
                    statesTimeoutDef = getTimeoutDeferred(10 * 1000);
                    statesTimeoutDef.promise.then(function () {
                        Debug.CrucialDataLoaded && console.log(this.name, "_detectAppReady - state timeout fired, assume states ready");
                        statesDef.resolve();
                    }.bind(this));
                    prms = statesTimeoutDef.promise;
                } else {
                    prms = Q.resolve();}


                return prms;
            }.bind(this)); // avoid statesTimeout firing when states have already arrived

            statesDef.promise.then(function () {
                Debug.CrucialDataLoaded && console.log(this.name, "AppReady - states ready");
                statesTimeoutDef && statesTimeoutDef.reject();
            }.bind(this)); // if the connection is closed before app ready, clear

            connCloseDef.promise.then(function () {
                Debug.CrucialDataLoaded && console.log(this.name, "AppReady - connection closed, intercept");
                structureDef && structureDef.reject();
                statesDef && statesDef.reject();
                statesTimeoutDef && statesTimeoutDef.reject();
                delayTimeoutDef && delayTimeoutDef.reject();
            }.bind(this)); // add another delay to the promises, so it'll resolve later than those two events.

            promises.push(Q.all([statesDef.promise, structureDef.promise]).then(function () {
                Debug.CrucialDataLoaded && console.log(this.name, "AppReady - structure ready & all states received");
                delayTimeoutDef = getTimeoutDeferred(delayTimeoutMax);
                return delayTimeoutDef.promise;
            }.bind(this)));
            Q.all(promises).then(function () {
                Debug.CrucialDataLoaded && console.log(this.name, "AppReady - everything ready & " + Math.round(delayTimeoutMax / 1000) + " seconds passed, emit CrucialDataLoaded");
                CompChannel.emit(CCEvent.CrucialDataLoaded);
            }.bind(this), function (err) {
                Debug.CrucialDataLoaded && console.log(this.name, "AppReady intercepted, one of the promises has been rejected!");
            }.bind(this));
        }; // methods exposed to the extensions


        var getActiveMiniserver = function getActiveMiniserver() {
            return activeMiniserverObj;
        };

        var getActiveMiniserverSerial = function getActiveMiniserverSerial() {
            return activeMiniserverObj ? activeMiniserverObj.serialNo : null;
        };

        ActMsComp.prototype.send = function send(cmd, encryptionType) {
            return ActiveMSCommunicationService.send(cmd, encryptionType);
        };

        ActMsComp.prototype.download = function download(cmd) {
            return ActiveMSCommunicationService.download(cmd);
        }; // StructureExt

        /**
         * shows the waiting screen when downloading the structure
         */


        ActMsComp.prototype.showDownloadingStructureWaiting = function showDownloadingStructureWaiting(downloadProgress) {
            NavigationComp.showWaitingView({
                state: WaitingState.Loading,
                miniserver: activeMiniserverObj,
                reachMode: reachMode,
                downloadProgress: downloadProgress
            });
        };
        /**
         * received structure ready
         * @param structure
         * @param {bool} newStructure
         */


        ActMsComp.prototype.structureReady = function structureReady(structure, newStructure) {
            if (newStructure) {
                // set the original remote url before any adoptions
                structure.msInfo.originalRemoteUrl = cloneObject(structure.msInfo.remoteUrl);
                // set the information of the root element in the structure
                // Wrike 87656134: we always use the information from the structure file, otherwise we won't be able to update the IP eg. to "nothing"
                if (reachMode === ReachMode.LOCAL) {
                    structure.msInfo.localUrl = activeMiniserverObj.localUrl;
                    activeMiniserverObj.remoteUrl = structure.msInfo.remoteUrl;
                } else if (reachMode === ReachMode.REMOTE) {
                    structure.msInfo.remoteUrl = activeMiniserverObj.remoteUrl;
                    activeMiniserverObj.localUrl = structure.msInfo.localUrl;
                }

                msInfoExt.setStructureInfos(structure.msInfo, structure.partnerInfo); // add the new miniserver to the archive

                activeMiniserverObj.msName = msInfoExt.getMiniserverName();
                activeMiniserverObj.location = msInfoExt.getMiniserverLocation();

                if (msInfoExt.getBrandingDate()) {
                    activeMiniserverObj.brandingDate = msInfoExt.getBrandingDate();
                } // password will be deleted in the add miniserver method - so set active ms before adding to the archive
                // EDIT: not anymore, miniserver object will be cloned in the add miniserver method


                if (PersistenceComponent.getMiniserver(msInfoExt.getMiniserverSerialNo())) {
                    PersistenceComponent.updateMiniserver(activeMiniserverObj);
                } else {
                    PersistenceComponent.addMiniserver(activeMiniserverObj);
                }
            } //console.time("Load StateContainers");


            return SandboxComponent.loadStateContainers().depActiveMsThen(function () {
                //console.timeEnd("Load StateContainers");
                CompChannel.emit(CCEvent.StructureReady, newStructure);
                (Feature.SHARED_USER_SETTINGS ? PersistenceComp.getSharedSettingsReadyPromise() : Q.resolve()).then(function () {
                    userSortingExt.handleStructureReceived().then(() => {
                        VendorHub.Usage.recordAfterMSChange();
                        this.emit(ActiveMSComp.ECEvent.STRUCTURE_READY, newStructure);
                    });
                }.bind(this));
            }.bind(this));
        };
        /**
         * no structure is ready for the user, show the error screen
         * possible reasons are e.g. download failed, no rooms, cats and functions, ...
         */


        ActMsComp.prototype.structureDownloadFailed = function structureDownloadFailed(error) {
            // if user cancels structure download, stop ms event was fired and set back all "active" information
            // archive should be shown then in most cases
            // The Miniserver responds with 423 which indicates that the user has been disabled.
            if (error === ResponseCode.FORBIDDEN) {
                NavigationComp.showCredentialsView({
                    state: LoginState.USER_IS_DISABLED,
                    miniserver: activeMiniserverObj
                });
            } else {
                NavigationComp.showErrorView({
                    errorCode: error,
                    miniserver: activeMiniserverObj,
                    reachMode: reachMode
                });
            }
        };
        /**
         * Setup a filename for the structure file depending on the reachMode (local/remote).
         * @returns {string} the string for the structure file name
         */


        ActMsComp.prototype.buildStructureFilename = function buildStructureFilename() {
            return buildLoxAPP3Filename(activeMiniserverObj, reachMode);
        };

        ActMsComp.prototype.getDefaultMiniserverSettings = function getDefaultMiniserverSettings() {
            return PersistenceComponent.getDefaultSettings().Miniserver;
        };

        ActMsComp.prototype.getManualFavoriteSettings = function getManualFavoriteSettings() {
            /*
            return {
                activated: false
            }; // manual favorites no longer available!
            */
            return PersistenceComponent.getMiniserverSettings().manualFavorites;
        };

        ActMsComp.prototype.getDeviceFavoriteSettings = function getDeviceFavoriteSettings() {
            return PersistenceComponent.getDeviceFavoritesForUser();
        };
        /**
         * Returns whether or not the device specific favortis are active.
         * @return {*}
         */


        ActMsComp.prototype.getDeviceFavoritesActive = function getDeviceFavoritesActive() {
            return (PersistenceComponent.getDeviceFavoritesForUser() || { activated: false }).activated;
        };
        /**
         *  Will return the device specific settings for either a control, a room or a category
         * @param uuid
         * @return {deviceFavorites|{activated, favorites}|*}
         */


        ActMsComp.prototype.getDeviceFavoriteSettingsFor = function getDeviceFavoriteSettingsFor(uuid) {
            var favorites = this.getDeviceFavoriteSettings().favorites,
                result;

            if (favorites) {
                result = favorites.controls[uuid] || favorites.rooms[uuid] || favorites.cats[uuid];
            }

            if (!result) {
                return {
                    isFavorite: false,
                    rating: 0
                };
            }

            return result;
        };
        /**
         * Will set a device specific settings for either a control, a room or a category
         * It will also dispatch the FavoritesChanged Event to the UI with the changed settings UUID
         * @param uuid
         * @param setting    the new setting value for the control/room/category
         */


        ActMsComp.prototype.setDeviceFavoriteSettingsFor = function setDeviceFavoriteSettingsFor(uuid, setting, avoidDispatchEvent) {
            var favorites = this.getDeviceFavoriteSettings().favorites,
                structMngr = ActiveMSComponent.getStructureManager(),
                grp = structMngr.getGroupByUUID(uuid),
                grpType = grp ? grp.groupType : false;

            if (favorites) {
                if (!setting.isFavorite && setting.rating === 0) {
                    // Remove any non favorite object with no rating
                    switch (grpType) {
                        case GroupTypes.ROOM:
                            delete favorites.rooms[uuid];
                            break;

                        case GroupTypes.CATEGORY:
                            delete favorites.cats[uuid];
                            break;

                        default:
                            delete favorites.controls[uuid];
                            break;
                    }
                } else {
                    switch (grpType) {
                        case GroupTypes.ROOM:
                            favorites.rooms[uuid] = setting;
                            break;

                        case GroupTypes.CATEGORY:
                            favorites.cats[uuid] = setting;
                            break;

                        default:
                            favorites.controls[uuid] = setting;
                            break;
                    }
                } // Store the updated reference.


                this.setDeviceFavorites(favorites);

                if (!avoidDispatchEvent) {
                    NavigationComp.dispatchEventToUI(NavigationComp.UiEvents.FavoritesChanged, uuid);
                }
            } else {
                throw new Error("Cannot update the device specific favorite setting. No device specific settings exist right now!");
            }
        };
        /**
         * Used to set a whole new set of device favorites.
         * @param favorites
         */
        //TODO-woessto: probably not needed as now the "adopt ui" takes the wheel when it comes to changing these settings.


        ActMsComp.prototype.setDeviceFavorites = function setDeviceFavorites(favorites) {
            PersistenceComponent.setDeviceFavorites(favorites);
        };

        ActMsComp.prototype.setManualFavoriteSettings = function setManualFavoriteSettings(favorites) {
            PersistenceComponent.setManualFavorites(favorites);
        };

        ActMsComp.prototype.getPresenceRoom = function getPresenceRoom() {
            return PersistenceComponent.getMiniserverSettings().presenceRoom;
        };

        ActMsComp.prototype.setEcoDarkenerActive = function setEcoDarkenerActive(dark, src) {
            CompChannel.emit(CCEvent.EcoScreenDarkenerActive, {dark, src});
        }
        ActMsComp.prototype.resetAmbientToDefaultLocation = function resetAmbientToDefaultLocation(fromButton = false) {
            CompChannel.emit(CCEvent.ResetAmbientToDefaultLocation, {fromButton: !!fromButton});
        }

        ActMsComp.prototype.setPresenceRoom = function setPresenceRoom(roomUUID) {
            PersistenceComponent.setPresenceRoom(roomUUID);
        };

        ActMsComp.prototype.setLastSelectedCodeTouch = function setLastSelectedCodeTouch(uuidSelectedCodeTouch) {
            PersistenceComp.setLastSelectedCodeTouch(uuidSelectedCodeTouch);
        };

        ActMsComp.prototype.getSortByRating = function getSortByRating(serialNo) {
            var settings = PersistenceComponent.getMiniserverSettings(serialNo),
                sortByRating; // maybe miniserver isn't added yet

            if (settings) {
                sortByRating = settings.sortByRating;
            }

            return sortByRating;
        };

        ActMsComp.prototype.setSortByRating = function setSortByRating(active, serialNo) {
            PersistenceComponent.activateSortByRating(active, serialNo, true);
        }; // MSInformationExt

        /**
         * @see MSInformation.receivedStructureDate
         */


        ActMsComp.prototype.receivedStructureDate = extension.Structure.prototype.receivedStructureDate;
        /**
         * @see MSInformation.getConfigVersion
         */

        ActMsComp.prototype.getConfigVersion = extension.MSInformation.prototype.getConfigVersion;
        /**
         * Checks if the current miniserver version is equal or higher than the given required one
         * @param requiredVersion
         * @returns {boolean} whether or not the version is met.
         */

        ActMsComp.prototype.hasRequiredConfigVersion = function hasRequiredConfigVersion(requiredVersion) {
            return new ConfigVersion(this.getConfigVersion()).greaterThanOrEqualTo(requiredVersion);
        };
        /**
         * @see MSInformation.getStructureDate
         */


        ActMsComp.prototype.getStructureDate = extension.MSInformation.prototype.getStructureDate;
        ActMsComp.prototype.setStructureDate = extension.MSInformation.prototype.setStructureDate;
        /**
         * returns the URL used for the current connection
         * when using CloudDNS, it's the resolved IP
         * @param doNotResolveCloudDNS prevents CloudDNS IP to be resolved
         */

        ActMsComp.prototype.getCurrentUrl = function getCurrentUrl(doNotResolveCloudDNS) {
            if (!doNotResolveCloudDNS && typeof currentUrl === "string" && currentUrl.length > 0) {
                return currentUrl;
            }

            var url = msInfoExt.getLocalUrl();

            if (reachMode === ReachMode.REMOTE) {
                if (doNotResolveCloudDNS) {
                    url = msInfoExt.getOriginalRemoteUrl();
                } else {
                    url = msInfoExt.getRemoteUrl();

                }
            }

            if (typeof url !== "string" || url.length === 0) {
                url = activeMiniserverObj.localUrl;
            }

            if (typeof url !== "string" || url.length === 0) {
                url = activeMiniserverObj.remoteUrl;
            }

            if (typeof url === "string" && url.length > 0) {
                return url;
            } else {
                throw new Error("can't get a current url, no Miniserver available!");
            }
        };
        /**
         * @see MSInformation.getLocalUrl
         */


        ActMsComp.prototype.getLocalUrl = extension.MSInformation.prototype.getLocalUrl;
        /**
         * @see MSInformation.getRemoteUrl
         */

        ActMsComp.prototype.getRemoteUrl = extension.MSInformation.prototype.getRemoteUrl;
        /**
         * @see MSInformation.getTempUnit
         */

        ActMsComp.prototype.getTempUnit = extension.MSInformation.prototype.getTempUnit;
        /**
         * @see MSInformation.getCurrencyString
         */

        ActMsComp.prototype.getCurrencyString = extension.MSInformation.prototype.getCurrencyString;

        /**
         * Returns a format string with the currently configured currency symbol.
         */
        ActMsComp.prototype.getCurrencyFormat = function getCurrencyFormat() {
            return "%.2f " + this.getCurrencyString();
        };

        /**
         * @see MSInformation.getMiniserverName
         */

        ActMsComp.prototype.getMiniserverName = extension.MSInformation.prototype.getMiniserverName;
        /**
         * @see MSInformation.setMiniserverSerialNo
         */

        ActMsComp.prototype.setMiniserverSerialNo = function setMiniserverSerialNo(serialNo) {
            activeMiniserverObj.serialNo = serialNo; // also update the ms object

            msInfoExt.setMiniserverSerialNo(serialNo);
        };

        ActMsComp.prototype.setMiniserverConnectionUrl = function setMiniserverConnectionUrl(url) {
            if (url.slice(-1) === "/") {
                url = url.replace(/.$/, "");
            }

            activeMiniserverObj.currConnectionUrl = url; // update the ms object
        };

        ActMsComp.prototype.getMiniserverConnectionUrl = function getMiniserverConnectionUrl() {
            return activeMiniserverObj.currConnectionUrl;
        };
        /**
         * @see MSInformation.setTlsInfo
         */


        ActMsComp.prototype.setTlsInfo = function setTlsInfo(reachMode, tlsStatus) {
            if (msInfoExt.getTlsInfo()[reachMode] !== tlsStatus) {
                // also update the ms object
                activeMiniserverObj.tlsInfo = msInfoExt.getTlsInfo();
                activeMiniserverObj.tlsInfo[reachMode] = tlsStatus;

                if (PersistenceComponent.getMiniserver(activeMiniserverObj.serialNo)) {
                    PersistenceComponent.updateMiniserver(activeMiniserverObj);
                } else {
                    PersistenceComponent.addMiniserver(activeMiniserverObj);
                } // update the tls info in the ms info ext


                msInfoExt.setTlsInfo(activeMiniserverObj.tlsInfo);
            }
        };
        /**
         * Sets the datacenter info to the current miniserver
         */


        ActMsComp.prototype.setDataCenter = function setDataCenter(dataCenter) {
            if (!activeMiniserverObj) {
                console.warn("ActiveMSComp", "setDataCenter: activeMsObject not known yet!");
                return;
            }

            if (msInfoExt.getDataCenter() !== dataCenter) {
                // also update the ms object
                activeMiniserverObj.dataCenter = msInfoExt.getDataCenter();
                activeMiniserverObj.dataCenter = dataCenter;

                if (PersistenceComponent.getMiniserver(activeMiniserverObj.serialNo)) {
                    PersistenceComponent.updateMiniserver(activeMiniserverObj);
                } else {
                    PersistenceComponent.addMiniserver(activeMiniserverObj);
                } // update the datacenter info in the ms info ext


                msInfoExt.setDataCenter(activeMiniserverObj.dataCenter);
            }
        };
        /**
         * Retrieves the dataCenter, either based on the remoteUrl provided, or from the MS info extension
         * @param [remoteUrl] optionally provided remote url to retrieve the dataCenter from
         */


        ActMsComp.prototype.getDataCenter = function getDataCenter(remoteUrl) {
            return getDataCenterFromRemoteUrl(remoteUrl) || msInfoExt.getDataCenter();
        };
        /**
         * Sets the trust info to the current miniserver
         */


        ActMsComp.prototype.setIsInTrust = function setIsInTrust(isInTrust) {
            if (activeMiniserverObj.isInTrust === isInTrust) {
                return; // no need to update anything.
            }
            activeMiniserverObj.isInTrust = isInTrust;

            if (PersistenceComponent.getMiniserver(activeMiniserverObj.serialNo)) {
                PersistenceComponent.updateMiniserver(activeMiniserverObj);
            } else {
                PersistenceComponent.addMiniserver(activeMiniserverObj);
            }
        };
        /**
         * @see MSInformation.getMiniserverSerialNo
         */


        ActMsComp.prototype.getMiniserverSerialNo = extension.MSInformation.prototype.getMiniserverSerialNo;

        ActMsComp.prototype.getCurrentUsername = function getCurrentUsername() {
            if (!activeMiniserverObj || !activeMiniserverObj.activeUser) {
                return null;
            }
            return activeMiniserverObj.activeUser;
        }

        ActMsComp.prototype.getCurrentCredentials = function getCurrentCredentials() {
            if (!activeMiniserverObj || !activeMiniserverObj.activeUser) {
                var stack = new Error().stack;
                setTimeout(function () {
                    console.error("Acquiring credentials while not having an active MS or active user set.");
                    console.error("Active MS: " + JSON.stringify(activeMiniserverObj));
                    console.error("Stack: " + JSON.stringify(stack));
                    NavigationComp.showArchive();
                    NavigationComp.requestDebuglog("Active Miniserver/User not set");
                }, 50);
                return {};
            }

            var creds = {
                    username: activeMiniserverObj.activeUser
                },
                password = "",
                token = ""; // password/tokens are passed around in its encrypted form, so we have to decrypt it first

            if (activeMiniserverObj.password) {
                password = VendorHub.Crypto.decrypt(activeMiniserverObj.password);
            }

            if (activeMiniserverObj.token) {
                token = VendorHub.Crypto.decrypt(activeMiniserverObj.token);
            } // check if we have actually a password or token given, it may be an empty string due to a bug


            if (token !== "") {
                creds.token = token;
            } else if (password !== "") {
                creds.password = password;
            }

            return creds;
        };
        /**
         * returns the current user from the structure (without password etc.)
         */


        ActMsComp.prototype.getCurrentUserFromStructure = extension.MSInformation.prototype.getCurrentUser;
        /**
         * returns a function to check if the current session is still the same as when this function is called
         * you can call the returned function at any time depending on your needs to check if the session is still the same/active
         * @returns {Function}
         */

        ActMsComp.prototype.getCurrentSessionCheck = function getCurrentSessionCheck() {
            var currentSessionNr = sessionNumber;
            return function () {
                return currentSessionNr === sessionNumber;
            };
        };
        /**
         * checks if the user is allowed to change his password (due to flag in Loxone Config)
         * and approves if it's save to let the user change the password eg. from remote
         * @returns {boolean}
         */


        ActMsComp.prototype.isUserAllowedToChangePW = function isUserAllowedToChangePW() {
            if (!Feature.CHANGE_PASSWORD || !msInfoExt.isUserAllowedToChangePW()) {
                return false;
            }

            var creds = ActiveMSComponent.getCurrentCredentials(),
                usingDefaultPw = checkDefaultUserPassCombi(creds.username, creds.password),
                connectedLocally = CommunicationComponent.getCurrentReachMode() === ReachMode.LOCAL;
            return !usingDefaultPw || connectedLocally;
        }; // TimeExt

        /**
         *
         */


        ActMsComp.prototype.showBigTimeDiffPopup = function showBigTimeDifference(popUpContent) {
            return NavigationComp.showPopup(popUpContent);
        };
        /**
         * @see Time.receivedMsDateTime
         */
        ActMsComp.prototype.receivedMsDateTime = extension.Time.prototype.receivedMsDateTime;
        /**
         * @see Time.receivedMsUtc
         */
        ActMsComp.prototype.receivedMsUtc = extension.Time.prototype.receivedMsUtc;
        /**
         * @see Time.receivedTimezoneId
         */
        ActMsComp.prototype.receivedTimezoneId = extension.Time.prototype.receivedTimezoneId;
        /**
         * Tries to fetch or load a public key for the miniserver
         * @returns {Promise}
         */
        ActMsComp.prototype.getPublicKey = function getPublicKey(host) {
            var def = Q.defer();

            if (Feature.ENCRYPTED_CONNECTION) {
                if (activeMiniserverObj.publicKey && this._verifyPublicKey(activeMiniserverObj.publicKey)) {
                    //console.log(" -> use cached public key..");
                    def.resolve(activeMiniserverObj.publicKey);
                } else if (this._publicKeyRequestPromise) {
                    def.resolve(this._publicKeyRequestPromise);
                } else {
                    // sendViaHTTP is always available!
                    // use HTTP because when encrypting a WebSocket command, it's very confusing with command queue
                    // in the WebSocket implementation if we request (in between) the public key over the WebSocket..
                    const msType = detectMiniserverType(activeMiniserverObj.serialNo);
                    if((Feature.CERTIFICATE_CHAIN_CHECK && msType !== MiniserverType.MINISERVER) || (msType === MiniserverType.MINISERVER && CommunicationComponent.getCurrentReachMode() === ReachMode.REMOTE)) {
                        this._publicKeyRequestPromise = def;
                        CommunicationComponent.sendViaHttpUnauthorized(Commands.ENCRYPTION.GET_CERTIFICATE, host).done((result) => {
                            const [ , intermediateCert, msCert] = extractCertificatesFromChain(result);
                            
                            CommunicationComponent.sendViaHttpUnauthorized(Commands.API_INFORMATION, host).then(({LL: { value }}) => {
                                let isCertificateChainValid = false;
                                let activeMsPublicKey = null;
                                try {
                                    const serialNo = JSON.parse(value.replaceAll("'", '"')).snr;
                                    isCertificateChainValid = verifyCertificateChain([msCert, intermediateCert, Root_CA], cleanSerialNumber(serialNo));
                                    activeMsPublicKey = extractMsPublicKey(msCert);
                                } catch (e) {
                                    console.error('Error while checking certificate chain', e);
                                }
                                

                                if (!isCertificateChainValid) {
                                    return NavigationComp.showPopup({
                                        title: _('miniserver.error.certificate-chain-invalid'),
                                        message: _('miniserver.error.certificate-chain-invalid.message'),
                                        color: Color.RED,
                                        icon: Icon.WARNING,
                                        buttonOk: _('miniserver.error.certificate-chain-invalid.proceed'),
                                        buttonCancel: true
                                    }).then((btn) => {
                                        if (btn === GUI.PopupBase.ButtonType.OK) {
                                            if(activeMsPublicKey) {
                                                activeMiniserverObj.publicKey = activeMsPublicKey;
                                                def.resolve(activeMsPublicKey);
                                                delete this._publicKeyRequestPromise;
                                            } else {
                                                this._requestPublicKey(host).then((pk) => {
                                                    def.resolve(pk);
                                                }).catch((e) => {
                                                    def.reject(e);
                                                });
                                            }                                                
                                        }
                                    }).catch((e ) => {
                                        console.error('Public key mismatch!');
                                        def.reject(new Error("Public key mismatch!"));
                                        NavigationComp.showArchive();
                                    })
                                } else {
                                    activeMiniserverObj.publicKey = activeMsPublicKey; // if the miniserver is stored already, also add it to the archive
                                    if (PersistenceComponent.getMiniserver(activeMiniserverObj.serialNo)) {
                                        PersistenceComponent.setNewPublicKey(activeMiniserverObj.serialNo, activeMsPublicKey);
                                    }
                                    def.resolve(activeMsPublicKey);
                                    delete this._publicKeyRequestPromise;
                                }
                            });
                        });
                    } else {
                        this._publicKeyRequestPromise = this._requestPublicKey(host).then(function (pk) {
                            def.resolve(pk);
                        }).catch(function (err) {
                            def.reject(err);
                        });
                    }
                }
            } else {
                def.reject(new Error("Encryption not supported by Miniserver!"));
            }

            return def.promise;
        };

        ActMsComp.prototype._requestPublicKey = function _requestPublicKey(host) {
            const cmd = Commands.ENCRYPTION.GET_PUBLIC_KEY;
            const def = Q.defer();
            CommunicationComponent.sendViaHttpUnauthorized(cmd, host).done(function (result) {
                delete this._publicKeyRequestPromise;
                var activeMsPublicKey = result.LL.value;
                activeMiniserverObj.publicKey = activeMsPublicKey; // if the miniserver is stored already, also add it to the archive

                if (PersistenceComponent.getMiniserver(activeMiniserverObj.serialNo)) {
                    PersistenceComponent.setNewPublicKey(activeMiniserverObj.serialNo, activeMsPublicKey);
                }

                def.resolve(activeMsPublicKey);
            }.bind(this), function () {
                def.reject(new Error("Requesting public key failed!"));
            });
            return def.promise;
        };
        /**
         * Ensures that development PKs will be replaced with proper ones.
         * @param pk            the current PK stored for this Miniserver
         * @return {boolean}    true if the stored one is right.
         * @private
         */


        ActMsComp.prototype._verifyPublicKey = function _verifyPublicKey(pk) {
            var verified = true,
                // IMPORTANT:
                //  • Replace every whitespace character (\s) with nothing to be able to check if this is the development public key!
                //  • Also replace every whitespace character (\s) of the DEV_PUBLIC_KEY to match with the tmpPk
                tmpPk = pk.replace(/\s/g, ""),
                // Use tmpPk to not manipulate the existing public key
                tmpDevPk = DEV_PUBLIC_KEY.replace(/\s/g, "");

            if (tmpPk === tmpDevPk && Feature.DEV_PK_RESET) {
                this.resetPublicKey();
                verified = false;
            }

            return verified;
        };
        /**
         * resets the publicKey for this Miniserver (may have changed..)
         * BEWARE: only reset if you have a good reason (eg. Mac address changed..), not due to an error code from Miniserver (Security!)
         */


        ActMsComp.prototype.resetPublicKey = function resetPublicKey() {
            activeMiniserverObj.publicKey = null; // also reset on current object (we have different instances!)

            PersistenceComponent.setNewPublicKey(activeMiniserverObj.serialNo, null);
        };

        ActMsComp.prototype.logOffUser = function logOffUser(resetActiveUser) {
            // ensure that the miniserver too invalidates the token. Will always resolve, even if a token could not be killed.
            return CommunicationComponent.killAllTokens().then(function () {
                PersistenceComponent.deleteConnectionInformation(weakThis.getMiniserverSerialNo(), resetActiveUser, true, true, true); // also unregister from PushNotifications

                pushNotificationService.deleteMiniserverForMac(activeMiniserverObj.serialNo);
            }.bind(this));
        };

        ActMsComp.prototype.disconnectMiniserver = function disconnectMiniserver(resetUrl) {
            Debug.MSSession && console.log(weakThis.name, "disconnectMiniserver");
            CompChannel.emit(CCEvent.StopMSSession, resetUrl);
        };
        /**
         * Will return a promsie that will resolve if the password is correct for the current user.
         * @param password
         * @param [msPermission]   an optional argument informing on what type of token to use.
         * @returns {*}
         */


        ActMsComp.prototype.verifyPassword = function verifyPassword(password, msPermission) {
            var creds = this.getCurrentCredentials(),
                promise;

            if (creds.password) {
                // old miniserver, we've got a password for this one.
                var def = Q.defer();
                promise = def.promise;

                if (creds.password === password) {
                    def.resolve();
                } else {
                    def.reject();
                }
            } else {
                if (!msPermission && msPermission !== 0) {
                    msPermission = MsPermission.WEB; // request a short lived regular token.
                } // new miniserver, password is no longer stored.. needs to acquire a token to check this password.


                promise = CommunicationComponent.requestToken({
                    user: creds.username,
                    password: password,
                    msPermission: msPermission
                });
            }

            return promise;
        }; // PersistenceComp

        /**
         * @see PersistenceComp.loadFile
         */


        ActMsComp.prototype.loadFile = PersistenceComp.loadFile;
        /**
         * @see PersistenceComp.saveFile
         */

        ActMsComp.prototype.saveFile = PersistenceComp.saveFile;
        /**
         * @see PersistenceComp.deleteAllStructures
         */

        ActMsComp.prototype.deleteAllStructures = function (reachMode) {
            PersistenceComp.deleteAllStructures(weakThis.getMiniserverSerialNo(), reachMode);
        };

        ActMsComp.prototype.hasSecureMSCredentials = function () {
            var credentials = weakThis.getCurrentCredentials(),
                result = false;

            if (credentials.token) {
                result = !CommunicationComponent.isTokenPassInsecure(credentials.token);
            } else {
                result = !checkDefaultUserPassCombi(credentials.username, credentials.password);
            }

            return result;
        };
        /**
         * Broadcasts 'MESSAGE_CENTER_UPDATE' event with the latest messageCenterStructure
         * @param messageCenterStructure
         */


        ActMsComp.prototype.onMessageCenterUpdate = function onMessageCenterUpdate(messageCenterStructure) {
            weakThis.emit(ActiveMSComp.ECEvent.MESSAGE_CENTER_UPDATE, messageCenterStructure);
        };
        /**
         * Broadcasts 'MESSAGE_CENTER_SPECIFIC_UPDATE' event with the specific entries for the provided sourceUuid
         * @param messageCenterEntries
         * @param sourceUuid
         */


        ActMsComp.prototype.onMessageCenterSpecificUpdate = function onMessageCenterSpecificUpdate(messageCenterEntries, sourceUuid) {
            weakThis.emit(ActiveMSComp.ECEvent.MESSAGE_CENTER_SPECIFIC_UPDATE + sourceUuid, messageCenterEntries, sourceUuid);
        }; // private methods


        ActMsComp.prototype.updateToLatestRelease = function updateToLatestRelease(zoneCtrl) {
            const currentAvailablePermissions = this.getAvailablePermissions();
            const hasPermission = hasBit(currentAvailablePermissions, MsPermission.TRIGGER_UPDATE);
            if(hasPermission) {
                return CommunicationComponent.sendViaHTTPWithPermission(Commands.UPDATE_TO_LATEST_RELEASE, MsPermission.TRIGGER_UPDATE, EncryptionType.NONE, false, HTTP_METHODS.GET).then(res => {
                    if(res && res.LL) {
                        const { value, Code: code } = res.LL;
                        if(value === '' && code === '200') {
                            return true;
                        }
                    }
                    return false;
                });
            }
        };


        /**
         * Offers all methods needed for handling the structure
         * @returns all methods which are public
         */


        var buildStructureManager = function buildStructureManager() {
            return {
                getCustomGroupTitles: structureExt.getCustomGroupTitles,
                getGlobalStateUUIDs: structureExt.getGlobalStateUUIDs,
                getControlByUUID: structureExt.getControlByUUID,
                getControlsByType: structureExt.getControlsByType,
                getControlsInGroup: structureExt.getControlsInGroup,
                getControlsInGroupForSorting: structureExt.getControlsInGroupForSorting,
                getGroupTypeByUUID: structureExt.getGroupTypeByUUID,
                getAllControls: structureExt.getAllControls,
                getAllSceneControls: structureExt.getAllSceneControls,
                getAllUsedControlTypes: structureExt.getAllUsedControlTypes,
                getSupportedControls: structureExt.getSupportedControls,
                getLinkedControlsOf: structureExt.getLinkedControlsOf,
                getGroupByUUID: structureExt.getGroupByUUID,
                getGroupsByType: structureExt.getGroupsByType,
                getMiniserverFavorites: structureExt.getMiniserverFavorites,
                getFavoriteGroupsByGroupType: structureExt.getFavoriteGroupsByGroupType,
                getFavoriteControls: structureExt.getFavoriteControls,
                getRatedControls: structureExt.getRatedControls,
                getCentralControls: structureExt.getCentralControls,
                getCentralRooms: structureExt.getCentralRooms,
                getGroupContentByUUID: structureExt.getGroupContentByUUID,
                getWeatherServer: structureExt.getWeatherServer,
                getOperatingModes: structureExt.getOperatingModes,
                getMediaServer: structureExt.getMediaServer,
                getMediaServerSet: structureExt.getMediaServerSet,
                getPresenceRooms: structureExt.getPresenceRooms,
                isPresenceControlUUID: structureExt.isPresenceControlUUID,
                setSortByRating: structureExt.setSortByRating,
                getTimes: structureExt.getTimes,
                getWeatherFieldTypes: structureExt.getWeatherFieldTypes,
                getAutopilotGenerator: structureExt.getAutopilotGenerator,
                getMessageCenter: structureExt.getMessageCenter,
                getCallerServices: structureExt.getCallerServices
            };
        };

        var _logMs = function _logMs(msObj) {
            var res = "-",
                clone = null;

            if (msObj) {
                clone = cloneObject(msObj);
                delete clone.token;
                delete clone.password;
                delete clone.publicKey;
                res = JSON.stringify(clone);
            }

            return res;
        };

        window[names.ext] = new ActMsComp();
        return window[names.ext];
    }]);
}



