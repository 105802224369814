'use strict';

define("AudioZoneV2ControlStateContainer", ["ControlStateContainer", "MediaBrowserV2Base"], function (ControlStateContainer) {
    return class AudioZoneV2ControlStateContainer extends ControlStateContainer {
        constructor(control) {
            super(control);
            this.name = "AudioZoneV2ControlStateContainer " + this.control.getName();

            if (this.control.audioserverComp && this.control.audioserverComp.getServerName) {
                this.name += "@" + this.control.audioserverComp.getServerName();
            } else {
                this.name += "@-unkown-";
            }

            this.states.texts = {};
            SandboxComponent.registerForStateChangesForUUID(this.control.details.server, this, this.serverStateContainerUpdated); // Register for AudioZone, this will register us for any audioEvents which are merged into this.states in the base

            if (this.control.audioserverComp && this.control.audioserverComp.registerForAudioZone) {
                this.playerRegID = this.control.audioserverComp.registerForAudioZone(control.details.playerid, this.serverStateReceived.bind(this), this.audioEventReceived.bind(this), null);
            }
        }

        destroy() {
            SandboxComponent.unregisterForStateChangesForUUID(this.control.details.server, this);

            this._stopTimeUpdater();

            super.destroy(...arguments);
        }

        // ------------------------------------------------------------------------
        //                    States from the Miniserver
        // ------------------------------------------------------------------------

        /**
         * handles incoming values and prepares the states (is also called when the MediaServerComp or the server state
         * container delivers new data)
         * values returned here are concerning both the zone and the server itself.
         * @param newVals
         */
        prepareStates(newVals) {
            this.newVals = newVals;
            Debug.Control.AudioZone.StateContainer && debugLog(this.name, "prepareStates");
            this.states.serverState = newVals[this.control.states.serverState];
            this.states.volumeStep = newVals[this.control.states.volumeStep] || 1;
            this.states.alarmVolume = newVals[this.control.states.alarmVolume];
            this.states.bellVolume = newVals[this.control.states.bellVolume];
            this.states.buzzerVolume = newVals[this.control.states.buzzerVolume];
            this.states.ttsVolume = newVals[this.control.states.ttsVolume];
            this.states.defaultVolume = newVals[this.control.states.defaultVolume];

            if (this.control.supportsBluetooth) {
                this.states.bluetoothPairingEnabled = newVals[this.control.states.bluetooth];
            } else {
                this.states.bluetoothPairingEnabled = false;
            }

            if (this.control.states.hasOwnProperty("isLocked")) {
                this.states.isLocked = newVals[this.control.states.isLocked];
            } else {
                this.states.isLocked = false;
            }

            this.states.power = !!newVals[this.control.states.power];
            /*if (this.states.power !== "on") {
                this.states.clientState = MusicServerEnum.ClientState.OFFLINE;
            } else {
                this.states.clientState = MusicServerEnum.ClientState.ONLINE;
            }*/


            this.states.clientState = newVals[this.control.states.clientState]; // The clientState is 1:1 the power state of the player on the audioserver
            // if a zone is synchronized and prepare a text.

            if (this.states.hasOwnProperty('isSynced') && this.states.hasOwnProperty(MusicServerEnum.Event.SYNCED_ZONES)) {
                this.states.texts.titlebar = this.control.getName();
            } // we don't wait for the socket to open up.


            this.states.serverReady = this.states.serverState === MusicServerEnum.ServerState.ONLINE && this.states.clientState === MusicServerEnum.ClientState.ONLINE; // Time

            if (this.states[MusicServerEnum.Event.TIME] >= 0 && this.states[MusicServerEnum.Event.DURATION] >= 0 && this.states[MusicServerEnum.Event.MODE]) {
                this._updateTimer(this.states[MusicServerEnum.Event.TIME], this.states[MusicServerEnum.Event.DURATION], this.states[MusicServerEnum.Event.MODE]);
            }

            this.states.isPlaying = this.states[MusicServerEnum.Event.MODE] === MusicServerEnum.EventAttr.Mode.PLAY;

            if (!this.states.isPlaying) {
                this._stopTimeUpdater();
            } // A volume change should lead to a temporary change in the state texts (at least for the cards)

            this.states.canPlayMusic = this.states.serverState === MusicServerEnum.ServerState.ONLINE && this.states.clientState === MusicServerEnum.ClientState.ONLINE && !this.states.isLocked && !this.states.universalIsLocked;

            if (!this.control.audioserverComp.isConnectionPromisePending || this.control.audioserverComp.isConnectionPromisePending()) {
                this.states.canPlayMusic = false;
            } // In case the Audioserver didn't update the audioEvent before rebooting we ad the canPlayMusic state to the isPlaying state


            this.states.isPlaying = this.states.isPlaying && this.states.canPlayMusic; // A volume change should lead to a temporary change in the state texts (at least for the cards)

            if (this.prevVolume !== this.states.volume && this.states.isPlaying && !this.control.externalUpDownVolume) {
                if (this.hasOwnProperty("prevVolume")) {
                    this.adoptedVolumeTimeout && clearTimeout(this.adoptedVolumeTimeout);
                    this.states.shouldShowVolume = true;
                    this.adoptedVolumeTimeout = setTimeout(function () {
                        this.adoptedVolumeTimeout = null;
                        this.states.shouldShowVolume = false;

                        this._updateStates();
                    }.bind(this), 3000);
                }

                this.prevVolume = this.states.volume;
            } // hide volume changes an events so the users can read what type of event did happen


            if (this.states.hasOwnProperty(MusicServerEnum.Event.EVENT_TYPE)) {
                this.states.shouldShowVolume = false;
                this.adoptedVolumeTimeout && clearTimeout(this.adoptedVolumeTimeout);
                this.adoptedVolumeTimeout = null;
            }

            this._prepareMediaInfo();

            this._prepareConnectivityInfo();


            Debug.Control.AudioZone.StateContainer && debugLog(this.control.getName() + " - serverState: " + this._getServerStateText(this.states.serverState).text + " (serverReady: " + this.states.serverReady + ")");
            Debug.Control.AudioZone.StateContainer && debugLog(this.control.getName() + " - clientState: " + this.states.clientState + " -> " + translateEnum(MusicServerEnum.ClientState, this.states.clientState));
        }

        getStateIcon() {
            return Icon.AudioZone.AUDIO_ZONE;
        }

        getStateText() {
            if (this.control.isConfigured()) {
                if (this.states.texts.connectivityText) {
                    return this.states.texts.connectivityText;
                } else {
                    return this.states.texts.subText;
                }
            } else {
                return _("unconfigured.title");
            }
        }

        getStateTextColor() {
            var color;

            if (this.control.isConfigured()) {
                if (this.states.serverState === MusicServerEnum.ServerState.INITIALIZING) {// don't alter the color, clientState offline may be resolved when the server is ready.
                } else if (this.states.serverState ===MusicServerEnum.ServerState.OFFLINE || this.states.clientState === MusicServerEnum.ServerState.OFFLINE || this.states.serverState === MusicServerEnum.ServerState.NOT_REACHABLE || this.states.clientState === MusicServerEnum.ServerState.NOT_REACHABLE || this.control.isNetworkClient()) {
                    color = window.Styles.colors.red;
                } else if (this.states.isPlaying) {
                    color = window.Styles.colors.stateActive;
                }
            } else {
                color = window.Styles.colors.orange;}


            return color;
        }

        getStateTextShort() {
            if (this.adoptedVolumeTimeout) {
                // temporarily show the current volume as text
                return lxFormat("%.0f%%", this.states.volume);
            } else if (!this.states.texts.connectivityText) {
                return this.states.texts.mainText;
            }
        }

        getStateColor() {
            var color;

            if (this.states.isPlaying) {
                color = window.Styles.colors.stateActive;
            }

            return color;
        }

        getStateTextForContent() {
            if (this.states.texts.connectivityText) {
                return this.states.texts.connectivityText;
            } else if (this.states.isPlaying) {
                return _("media.item.playing");
            } else {
                return _("media.item.paused");
            }
        }

        /**
         * If a zone is synced, the small icon will represent it.
         */
        getStateIconSmall() {
            var icon = null;

            if (this.states.isLocked) {
                icon = {
                    iconSrc: Icon.INFO2,
                    color: window.Styles.colors.orange
                };
            } else if (this.states.isSynced) {
                icon = {
                    iconSrc: Icon.AudioZone.GROUPED,
                    color: this.states.syncColor
                };
            }

            return icon;
        }

        /**
         * States from the mediaStateContainer that receives the updates of the MediaServer.
         * @param states    music server states, adopted by mediaStateContainer
         * @note Musicserver v1 and v2
         * @private
         */
        serverStateContainerUpdated(states) {
            Debug.Control.AudioZone.StateContainer && console.log(this.name, "serverStateContainerUpdated: ", states, getStackObj());
            this.states.isSynced = false;
            this.states.syncColor = null;
            this.states[MusicServerEnum.Event.SYNCED_ZONES] = [];
            this.states.connStateText = states.connStateText;
            this.states.connState = states.connState;
            this.states.features = states.features;
            var players; // check every group

            states.grouping && states.grouping.forEach(function (currGroup) {
                // check every groups player
                players = currGroup[MusicServerEnum.Event.PLAYERS];

                if (players && Array.isArray(players)) {
                    currGroup[MusicServerEnum.Event.PLAYERS].forEach(function (currPlayer) {
                        // is this state containers player in this group?
                        if (currPlayer[MusicServerEnum.Event.PLAYER_ID] === this.control.details.playerid) {
                            // yes, set the states and return.
                            this.states.isSynced = true;
                            this.states.syncColor = currGroup.color;
                            this.states[MusicServerEnum.Event.SYNCED_ZONES] = currGroup[MusicServerEnum.Event.PLAYERS];

                            if (Debug.Control.AudioZone.MasterVolume && this.states[MusicServerEnum.Event.MASTER_VOLUME] !== currGroup[MusicServerEnum.Event.MASTER_VOLUME]) {
                                console.log(this.name, "serverStateContainerUpdated - masterVolume changed to " + currGroup[MusicServerEnum.Event.MASTER_VOLUME]);
                            }

                            if (currGroup.hasOwnProperty(MusicServerEnum.Event.MASTER_VOLUME)) {
                                this.states[MusicServerEnum.Event.MASTER_VOLUME] = currGroup[MusicServerEnum.Event.MASTER_VOLUME];
                            }
                        }
                    }.bind(this));
                } else {
                    console.warn(this.name, "serverStateContainerUpdated - grouping info without players received! " + JSON.stringify(currGroup));
                }
            }.bind(this));
            Debug.Control.AudioZone.StateContainer && console.log("  - grouping", JSON.stringify(states.grouping));
            Debug.Control.AudioZone.StateContainer && console.log("  - isSynced: " + JSON.stringify(this.states.isSynced));
            Debug.Control.AudioZone.StateContainer && console.log("  - " + JSON.stringify(this.states[MusicServerEnum.Event.SYNCED_ZONES]));

            this._updateStates();
        }

        // ------------------------------------------------------------------------
        //                    States from the MediaServerComponent
        // ------------------------------------------------------------------------

        /**
         * Receives events concerning the state of the music server itself. Calls prepareStates and notifyListeners
         * @param newVals
         * @note Musicserver v1 only
         */
        serverStateReceived(newVals) {
            var tmpVals = cloneObjectDeep(newVals);
            var newHash = JSON.stringify(tmpVals).hashCode();

            if (newHash === this._prevServerStateHash) {
                //Debug.Control.AudioZone.StateContainer &&
                console.warn(this.name, "serverStateReceived: avoided duplicate update ", getStackObj());
                return;
            }

            this._prevServerStateHash = newHash;
            Debug.Control.AudioZone.StateContainer && console.log(this.name, "serverStateReceived: ", cloneObjectDeep(tmpVals), getStackObj()); // convert "state" to "serverState" before merging

            delete tmpVals.state;

            this._mergeWithStates(tmpVals);

            this.states.isCompCommReady = !!newVals.reachable && tmpVals.serverState === MusicServerEnum.ServerState.ONLINE;

            this._updateStates();
        }

        /**
         * Receives audio events from the MediaServerComp concerning this very zone. Calls prepareStates and notifyListeners
         * The event originates either from an audio_event on one of the sockets or from a status-request-response
         * @param newVals
         */
        audioEventReceived(newVals) {
            try { // added to narrow down issue RN1-I319
                var clonedNewVals = cloneObjectDeep(newVals),
                    newHash = JSON.stringify(clonedNewVals).hashCode();

                if (newHash === this._prevAudioValueHash) {
                    // Audio1-I2130
                    return;
                }

                this._prevAudioValueHash = newHash;
                Debug.Control.AudioZone.StateContainer && console.log(this.name, "audioEventReceived", cloneObjectDeep(clonedNewVals));

                try {
                    this._mergeWithStates(clonedNewVals);
                } catch (ex4) {
                    console.error(this.name, "audioEventReceived - failed at mergeWithStates!");
                    throw ex4;
                }

                if (!clonedNewVals.hasOwnProperty(MusicServerEnum.Event.EVENT_TYPE)) {
                    delete this.states[MusicServerEnum.Event.EVENT_TYPE];
                } // important to also delete the source-flag when it's no longer being sent. mergeWithStates won't remove it.


                if (this._isEmptyOrNull(clonedNewVals[MusicServerEnum.Event.SOURCE_NAME])) {
                    this.states[MusicServerEnum.Event.SOURCE_NAME] = null;
                } // Respond to audioType


                try {

                    if (clonedNewVals.hasOwnProperty(MusicServerEnum.Event.AUDIO_TYPE)) {
                        this._updateAudioType(clonedNewVals);
                    } // Sync
                } catch (ex5) {
                    console.error(this.name, "audioEventReceived - failed at updateAudioType!");
                    throw ex5;
                }


                if (this.states.hasOwnProperty(MusicServerEnum.Event.SYNCED_ZONES)) {
                    this.states.isSynced = this.states[MusicServerEnum.Event.SYNCED_ZONES].length > 0;
                } else {
                    this.states.isSynced = false;
                }

                delete this.states.contentType;

                try {
                    if (this.states.hasOwnProperty("type") && (this.states.hasOwnProperty("audiopath") || this.states.hasOwnProperty("id"))) {
                        Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(this.states);
                    }
                } catch (ex2) {
                    console.error(this.name, "audioEventReceived - failed at applyContentTypeToItem via MediaBrowserBase");
                    throw ex2;
                }

                try {
                    if (this.states.hasOwnProperty("parent") && this.states.parent) {
                        delete this.states.parent.contentType;
                        Controls.AudioZoneV2Control.MediaBrowserV2Base.applyContentTypeToItem(this.states.parent);
                    }
                } catch (ex3) {
                    console.error(this.name, "audioEventReceived - failed at applyContentTypeToItem of PARENT via MediaBrowserBase");
                    throw ex3;
                }

                try {
                    this._updateStates();
                } catch (ex1) {
                    console.error(this.name, "audioEventReceived - this._updateStates failed with ex", ex1);
                    console.error(this.name, "   is this.control available? " + (this.control ? ("yes, it's " + this.control.getName()) : "no!"));
                    console.error(this.name, ex1);
                    throw ex1;
                }
            } catch (ex) {
                console.error(this.name, "audioEventReceived failed with exception: " + JSON.stringify(ex));
                console.error(this.name, "   failed at processing the event: " + JSON.stringify(newVals));
                console.error(this.name, "   log introduced to narrow down RN1-I319 where audioCentral remained empty.");
                console.error(this.name, "   is this.control available? " + (this.control ? ("yes, it's " + this.control.getName()) : "no!"));
                console.error(this.name, ex1);
                debugger;
                throw ex;
            }
        }

        /**
         * Allows any component to inject states into the stateContainer
         * The Argument list contains obj
         */
        injectStates(statesToMerge) {
            this._mergeWithStates(statesToMerge);

            this._updateStates();
        }

        /**
         * Returns an array of object with at least a "name" and "id" property
         * @param sourceState
         * @return {*[]}
         */
        getAutomaticDesignerStateObjectsFromState(sourceState) {
            var objectStates;

            switch (sourceState) {
                case "favorites":
                    // this is a manually injected state by the FavoritesManager!
                    if (this.states[sourceState] instanceof Array) {
                        objectStates = Object.values(this.states[sourceState]).map(function (favState) {
                            return {
                                id: favState.id,
                                // check for both title and name
                                name: favState.title || favState.name
                            };
                        });
                    } else {
                        // Just in case there is something wrong with the Audioserver
                        objectStates = [];
                    }

                    break;

                default:
                    objectStates = super.getAutomaticDesignerStateObjectsFromState(...arguments);
            }

            return objectStates;
        }

        // ------------------------------------------------------------------------
        //                    Handling Audio Events
        // ------------------------------------------------------------------------
        //  Audio Type
        _updateAudioType(event) {
            var audioType = event[MusicServerEnum.Event.AUDIO_TYPE];
            this.states.isStream = this.control.audioserverComp.isStream(audioType);

            if (!this.states.isStream) {
                if (event.hasOwnProperty(MusicServerEnum.Event.DURATION) && event[MusicServerEnum.Event.DURATION] === 0) {
                    console.warn("This 'track' is not a stream, but it has 0 duration!");
                }

                if (!event.hasOwnProperty(MusicServerEnum.Event.DURATION)) {
                    console.warn("This 'track' is not a stream and it has no duration! it must have a duration");
                }
            }

            if (this.states.isStream) {
                var hasDuration = event.hasOwnProperty(MusicServerEnum.Event.DURATION);
                hasDuration = hasDuration && event[MusicServerEnum.Event.DURATION] > 0;

                // don't know if this is still required, but for soundsuit it definitely isn't
                if (!this.states.audiopath.hasPrefix("soundsuit@")) {
                    this.states.isStream = !hasDuration; // if it is a stream, but we have a duration, don't treat it as one
                }
            }
        }

        _mapStateUuidToEventName(states, uuid, evntName, isText) {
            if (states.hasOwnProperty(uuid)) {
                if (isText) {
                    this.states[evntName] = states[uuid].text;
                } else {
                    this.states[evntName] = states[uuid];
                }
            }
        }

        //  Time/Duration
        _updateTimer(time, duration, mode) {
            if (!this._timeInfoDidChange(Math.round(time), Math.round(duration), mode)) {
                return;
            }

            this.currTime = Math.round(time);
            this.duration = Math.round(duration);
            this.states[MusicServerEnum.Event.TIME] = this.currTime;
            this.states[MusicServerEnum.Event.DURATION] = this.duration;
            this.startTimeStamp = timingNow();
            this.startTime = this.currTime;

            this._updateProgress(this.currTime, this.duration); // update right away


            if ((mode === "play" || mode === "resume") && !this.timeUpdater && this.duration > 0) {
                this.timeUpdater = setInterval(function () {
                    var delta = timingDelta(this.startTimeStamp);
                    var newTime = parseInt(this.startTime + delta / 1000);

                    if (newTime !== this.currTime) {
                        this.currTime = newTime;

                        this._updateProgress(this.currTime, this.duration);

                        this._updateStates(true);
                    }
                }.bind(this), 100);
            } else if (mode === "stop" || mode === "pause") {
                this._stopTimeUpdater();
            } // if the server or client is down - the timer is stopped inside prepare states

        }

        _timeInfoDidChange(time, duration, mode) {
            var changed = this.__lastUpdTime !== time || this.__lastUpdDuration !== duration || this.__lastUpdMode !== mode;

            if (changed) {
                this.__lastUpdTime = time;
                this.__lastUpdDuration = duration;
                this.__lastUpdMode = mode;
            }

            return changed;
        }

        _stopTimeUpdater() {
            clearInterval(this.timeUpdater);
            this.timeUpdater = null;
        }

        _checkTimeString(time) {
            return time < 10 ? '0' + time : time;
        }

        _updateProgress(time, duration) {
            if (!this.states.texts.hasOwnProperty("time")) {
                this.states.texts.time = {
                    current: "-10",
                    total: "-10"
                };
            } // the regular time+duration attribute may be overwritten by incoming audio events (e.g. vol change),
            // in order to avoid jumping progress bars, introduce internal properties that will only be updated
            // if the app deems it necessary (avoids jumping back in time)


            this.states[MusicServerEnum.Event.TIME_INTERNAL] = time;
            this.states[MusicServerEnum.Event.DURATION_INTERNAL] = duration;

            var minutes = this._checkTimeString(Math.floor(time / 60));

            var seconds = this._checkTimeString(Math.floor(time - minutes * 60));

            var bygoneTime = minutes + ':' + seconds;
            var totalTime = null;

            if (duration - time >= 0) {
                minutes = this._checkTimeString(Math.floor(duration / 60));
                seconds = this._checkTimeString(Math.floor(duration - minutes * 60));
                totalTime = minutes + ':' + seconds;
            }

            if (this.states.isStream) {
                bygoneTime = "";
                totalTime = "";
            }

            if (bygoneTime !== this.states.texts.time.current || totalTime !== this.states.texts.time.total) {
                this.states.texts.time = {
                    current: bygoneTime,
                    total: totalTime
                };
            }
        }

        // ------------------------------------------------------------------------
        //                    Preparing States
        // ------------------------------------------------------------------------
        _isEmptyOrNull(val) {
            return !val || val === " " || val === NBR_SPACE || val === null;
        }

        _hasStateText(id) {
            return !this._isEmptyOrNull(this.states[id]);
        }

        _prepareMediaInfo() {
            var mainText = "";
            var subText = "";

            if (this._hasStateText(MusicServerEnum.Event.TITLE)) {
                mainText = this.states[MusicServerEnum.Event.TITLE];

                if (this._hasStateText(MusicServerEnum.Event.ARTIST)) {
                    subText = this.states[MusicServerEnum.Event.ARTIST];
                }

                if (this._hasStateText(MusicServerEnum.Event.ALBUM)) {
                    if (subText) {
                        subText += " (" + this.states[MusicServerEnum.Event.ALBUM] + ")";
                    } else {
                        subText = this.states[MusicServerEnum.Event.ALBUM];
                    }
                }

                if (mainText === "") {
                    // if the title was empty, swap main and subtext
                    mainText = subText;
                    subText = "";
                }
            } // maybe we have a radioStation set?


            if (this._hasStateText(MusicServerEnum.Event.STATION)) {
                if (subText !== "") {
                    mainText += SEPARATOR_SYMBOL + subText;
                }

                subText = mainText;
                mainText = this.states[MusicServerEnum.Event.STATION];
            } // if we have nothing - maybe the Music Server isn't ready yet? if so, don't show "no music selected"


            if (mainText === "" && subText === "" && this.states.canPlayMusic) {
                mainText = _("media.no-music-selected");
                subText = "";
            }

            if ((subText === "" || !subText) && this._currentItemIsLineIn()) {
                var srcSvrSerial = this._getServerSerialFromLineInPath(this.states.audiopath);

                var sourceSvr = this.control.audioserverComp.getMediaServerBySerial(srcSvrSerial);

                if (sourceSvr) {
                    subText = this.control.audioserverComp.getServerName(null, sourceSvr);
                }
            } // if a source is known, show it!


            if (this._hasStateText(MusicServerEnum.Event.SOURCE_NAME)) {
                if (subText && subText !== "") {
                    subText += SEPARATOR_SYMBOL + this.states[MusicServerEnum.Event.SOURCE_NAME];
                } else {
                    subText = this.states[MusicServerEnum.Event.SOURCE_NAME];
                }
            } // at last, ensure that no encoding was used that now messes with the media info.


            try {
                mainText = decodeURIComponent(mainText);
                subText = decodeURIComponent(subText);
            } catch (ex) {// don't worry if decoding isn't possible.
            }

            this.states.texts.mainText = mainText;
            this.states.texts.subText = subText;
        }

        _getServerSerialFromLineInPath(audioPath) {
            return audioPath.split("#")[0].split(":")[1];
        }

        _currentItemIsLineIn() {
            return this.states.contentType && this.states.contentType === MusicServerEnum.MediaContentType.LINEIN;
        }

        _getServerStateText(serverState) {
            var serverText = "",
                shortText = null;

            switch (serverState) {
                case MusicServerEnum.ServerState.ONLINE:
                    // nothing to worry about
                    break;

                case MusicServerEnum.ServerState.OFFLINE:
                case MusicServerEnum.ServerState.NOT_REACHABLE:
                    serverText = _("device-offline");
                    break;

                case MusicServerEnum.ServerState.INITIALIZING:
                    serverText = _("audio-server.conn.establish-connection");
                    break;

                case MusicServerEnum.ServerState.MUSICSERVER_REBOOTING:
                    serverText = _("audio-server.conn.booting"); // use new universal text that doesn't focus on the audioserver
                    break;

                case MusicServerEnum.ServerState.UNKNOWN:
                    serverText = _("unknown");
                    break;

                default:
                    console.error("The audioZoneStateContainer received an invalid server state! " + this.states.serverState); // some unhandled error, maybe invalid zone?

                    break;
            }

            return {
                text: serverText,
                short: shortText
            };
        }

        _getClientStateText(clientState) {
            if (this.states.serverState < MusicServerEnum.ServerState.ONLINE) {
                return this._getServerStateText(this.states.serverState);
            }

            var serverText = "",
                shortText = null;

            switch (clientState) {
                case MusicServerEnum.ClientState.ONLINE:
                    // nothing to worry about
                    break;

                case MusicServerEnum.ClientState.OFFLINE:
                case MusicServerEnum.ClientState.NOT_REACHABLE:
                    serverText = _("service-offline");
                    break;

                case MusicServerEnum.ClientState.INITIALIZING:
                    serverText = _("audio-server.conn.establish-connection");
                    break;
                case MusicServerEnum.ClientState.AUDIO_ZONE_REBOOTING:
                    serverText = _("audio-server.conn.service-booting");
                    break;

                default:
                    console.error("The audioZoneStateContainer received an invalid client state! " + this.states.clientState); // some unhandled error, maybe invalid zone?

                    break;
            }

            return {
                text: serverText,
                short: shortText
            };
        }


        _prepareConnectivityInfo() {
            var connTextObj = this._getClientStateText(this.states.clientState),
                connectivityText = connTextObj.text,
                connTextShort = connTextObj.short;

            if (connectivityText === "") {
                this.states.texts.connectivityText = null;
            } else {
                this.states.texts.connectivityText = connectivityText;
            }

            this.states.texts.connectivityTextShort = connTextShort;
        }

        // ------------------------------------------------------------------------
        //                    Helper
        // ------------------------------------------------------------------------
        _mergeWithStates(newVals) {
            updateFields(this.states, newVals);
        }

        /**
         * All listeners will be informed after prepareStates is being called. Also takes care of the version counter that
         * is used for views to identify whether or not they need to update their UI
         * @private
         */
        _updateStates(noPrepare) {
            delete this.states.name;
            this.version++; // count up version with each update

            !noPrepare && this.newVals && this.prepareStates(this.newVals);
            this.notifyListener();
        }

    };
});
